import moment from "moment";
import countries from "i18n-iso-countries";
import {Locale, supportedLocales} from "@ct-react/locale";

const importMomentJsLocale = async (locale: Locale) => {
  switch (locale.code.toLowerCase()) {
    case "en-gb":
      // @ts-ignore
      await import("moment/locale/en-gb");
      return "en-gb";
    case "de-ch":
      // @ts-ignore
      await import("moment/locale/de-ch");
      return "de-ch";
    default:
      // @ts-ignore
      await import("moment/locale/fr-ch");
      return "fr-ch";
  }
};

const loadCountries = async ():Promise<void> => {
  const loadRequiredLanguages = supportedLocales.map(async l =>
    countries.registerLocale(await import(`i18n-iso-countries/langs/${l.basename}.json`)));
  return Promise.all(loadRequiredLanguages).then();
}


export const handleMomentJsLocale = async (locale: Locale): Promise<void> =>
  importMomentJsLocale(locale).then(ml => moment.locale(ml)).then(loadCountries);
