import React,{
    FunctionComponent,
    RefObject,
    useEffect,
    useRef,
    useState
} from "react";
import gsap from "gsap";
import { useIntl } from "react-intl";
import {Header,
    Section
} from "../../components/wrapper/section";
import { ImageBox,
    Textbox,
    ThreeColumn,
    TwoColumn
} from "../../components/wrapper/column";
import {ButtonTelNumber} from "../../components/common";

import headerImg from "../../assets/au-club-immobilier-champex.jpg";
import image1 from "../../assets/club-alpin-immobilier-champex.jpg";
import image2 from "../../assets/Photo-ACA-suite.jpg";
import image3 from "../../assets/20201210_091642-02.jpg";
import image4 from "../../assets/118821030_314397296490937_80200142321529432_n.jpg";
import "./home.scss";

const Home:FunctionComponent = () => {
    const intl = useIntl();
    const [section_1_Y, setSection_1_Y] = useState<number>(0);
    const [section_2_Y, setSection_2_Y] = useState<number>(0);

    const section_1_image = useRef<HTMLDivElement|null>(null);
    const section_2_image = useRef<HTMLDivElement|null>(null);

    //Init intersectObserver
    useEffect(() => {
        if(!!section_1_image && !!section_2_image){
            intersect_section_1(section_1_image)
            intersect_section_2(section_2_image)
        }
      }, []);
    //Animate section 1 images
    useEffect(()=>{
    const image1 = document.querySelector(".agency .image:nth-child(1)")
    const image2 = document.querySelector(".agency .image:nth-child(2)")
    if(section_1_Y>0){
        gsap.to(image1,{
            x:0,
            autoAlpha:1,
            duration:1,
        })
        gsap.to(image2,{
            x:"-50%",
            autoAlpha:1,
            duration:1,
            delay:1
        })
    }
    },[section_1_Y]);
    //Animate section 2 image
    useEffect(()=>{
    const image1 = document.querySelector(".expertise .image:nth-child(1)")
    const image2 = document.querySelector(".expertise .image:nth-child(2)")
    if(section_2_Y>0){
        gsap.to(image1,{
            x:0,
            autoAlpha:1,
            duration:1,
        })
        gsap.to(image2,{
            x:"-50%",
            autoAlpha:1,
            duration:1,
            delay:1
        })
    }
    },[section_2_Y]);

    //intersect observer section 1
    const intersect_section_1 = (elementRef: RefObject<Element>)=>{
    if(elementRef!=null){
        const node = elementRef?.current! // DOM Ref
        const observer = new IntersectionObserver(
            ([entry]:any) => {
                setSection_1_Y(entry.intersectionRect.x)
            },
            { rootMargin: window.innerWidth>900?"-50%":"-40%" }
            );
            elementRef&&observer.observe(node)
            return () => observer.disconnect()
    }
    };
    //intersect observer section 2
    const intersect_section_2 = (elementRef: RefObject<Element>)=>{
    if(elementRef!=null){
        const node = elementRef.current! // DOM Ref
        const observer = new IntersectionObserver(
            ([entry]:any) => {
                setSection_2_Y(entry.intersectionRect.x)
            },
            { rootMargin: window.innerWidth>900?"-50%":"-40%" }
            );
            elementRef&&observer.observe(node)
            return () => observer.disconnect()
    }
    };

    return(
        <>
        <Header subtitle={intl.formatMessage({id: "title-agence-champex-lac", defaultMessage: "Agence champex-lac"})} img={headerImg}>
            {intl.formatMessage({id: "title-agence-champex-lac-label", defaultMessage: "Au{break}club{break}immobilier"},{break: <br/>})}
        </Header>
        <Section id="agency" className="agency" color="var(--primary)">
            <TwoColumn>
                <div ref={section_1_image} className="left-box">
                    <ImageBox title={intl.formatMessage({id: "title-notre-agence", defaultMessage: "Notre agence"})} image1={image1} image2={image2}/>
                </div>
                <div className="right-box">
                    <Textbox>
                        <p>
                            {intl.formatMessage({id: "home-paragraphe-1", defaultMessage: "Bienvenue Au Club Immobilier, une agence à taille humaine où se partagent <strong>des projets de vie, des émotions et des rêves</strong>. Ce rêve d’agence, c’est celui de son fondateur, le financier Michel Thétaz, déjà connu pour avoir créé l’équipe Cycliste « IAM Cycling » et l’Hôtel, Restaurant et Spa « Au Club Alpin ». Située au cœur de la station de Champex-Lac, Au Club Immobilier est une agence active en Suisse et à l’étranger dont l’équipe, experte et flexible, n’a qu’un seul but, vous accompagner et vous guider tout au long de votre projet immobilier. Nous sommes spécialisés dans les domaines suivants : vente, acquisition, location et gestion administrative de biens immobiliers. Contactez-nous ! Nous serons heureux de pouvoir vous renseigner et vous accueillir au sein de notre agence dans un cadre d’exception !"})}
                        </p>
                        <ButtonTelNumber mail="contact@auclubimmobilier.ch " />
                    </Textbox>
                </div>
            </TwoColumn>
        </Section>
        <Section id="services" className="service" color="var(--primary)">
            <ThreeColumn title={intl.formatMessage({id: "title-nos-services", defaultMessage : "Nos services"})}>
                <div>
                    <div className="serviceIcone">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                            <path fill="var(--primary)" d="M48.01,24.67c-.45-.6-.45-1.2,0-1.8,3.57-4.77,7.13-9.54,10.68-14.32l.78-1.05c.64-.85,.48-2.16-.34-2.86-.44-.38-1-.55-1.76-.55H6.51s-.06-1.11-.06-1.11c0-.24-.01-.48-.01-.72,0-.51,0-.99-.07-1.45C6.29,.18,5.76,.02,5.32,0c-.02,0-.04,0-.06,0C4.93,0,4.38,.13,4.24,.65c-.08,.29-.12,.6-.12,.91,0,20.25,0,40.5,0,60.75,0,.24,.01,.48,.04,.72,.06,.47,.25,.73,.65,.88,.52,.19,.92,.12,1.31-.21,.2-.17,.34-.43,.34-1.11v-19.12s36.4,0,50.9-.01c.38,0,.79-.07,1.2-.21,.59-.2,1.04-.68,1.24-1.32,.2-.64,.1-1.32-.27-1.82-3.83-5.15-7.66-10.29-11.5-15.42ZM6.48,41.07V6.46H57.33s-8.49,11.4-11.3,15.14c-1.03,1.36-1.03,2.99,0,4.34l11.31,15.12H6.48Z"/>
                        </svg>
                    </div>
                    <h4>{intl.formatMessage({id: "title-estimation", defaultMessage : "Estimation précise"})}</h4>
                    <p>
                        {intl.formatMessage({id: "home-paragraphe-2", defaultMessage: "Bénéficiez de conseils avisés de notre équipe de spécialistes et d’un service gratuit qui vous fera gagner du temps et de l’argent. Notre objectif : vendre rapidement et au meilleur prix."})}
                    </p>
                </div>
                <div>
                    <div className="serviceIcone">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                            <path fill="var(--primary)" d="M55.95,12.87h-.15c-1.16-.01-2.3-.02-3.43-.02-1.43,0-2.86,0-4.29,.02h-.51s-.08,0-.12,0c-.63,0-1.17-.45-1.28-1.06-.32-1.04-.67-2.15-1.04-3.21-.29-.84-.78-1.62-1.41-2.24-.98-.97-2.28-1.5-3.66-1.5h-.16c-1.72,0-3.42,0-5.11,0s-3.39,0-5.09,0c-1.7,0-3.39,0-5.09,0-.37,0-.72,.02-1.08,.05-1.88,.11-3.51,1.28-4.2,2.99-.43,.99-.79,2.03-1.14,3.03-.12,.34-.24,.68-.36,1.01-.15,.54-.64,.92-1.21,.92-.04,0-.07,0-.11,0-1.34,0-2.68,0-4.02,0s-2.71,0-4.06,0c-.5,0-1,.05-1.49,.12-1.86,.27-3.55,1.29-4.64,2.8-.96,1.29-1.47,2.87-1.44,4.47v30.08c0,.74,0,1.44,.05,2.14,.09,1.49,.67,2.93,1.64,4.06,1.38,1.66,3.41,2.6,5.55,2.6,.07,0,.14,0,.21,0H32c7.91,0,15.83,0,23.75-.01h0c.96,0,1.85-.16,2.7-.48,2.81-1.04,4.7-3.76,4.69-6.76,.01-10.6,.01-21.19,0-31.79,.01-1.41-.39-2.76-1.17-3.93-1.33-2.07-3.58-3.3-6.02-3.3Zm4.91,38.84c.05,.91-.15,1.86-.59,2.7-.8,1.47-2.31,2.4-3.97,2.44-.17,.01-.38,.01-.59,.01H8.18s-.14,0-.23,0c-1.13,0-2.24-.39-3.12-1.09-.92-.76-1.5-1.81-1.65-2.98-.04-.3-.06-.61-.05-.91V20.13c-.08-1.52,.61-3.03,1.83-3.99,.85-.64,1.88-.99,2.93-.99,.06,0,.13,0,.19,0h3.57s6.33,0,6.33,0h.09s.1,.01,.15,.01c.63,0,1.18-.44,1.32-1.06v-.04s.02-.04,.02-.04l1.55-4.65c.34-1.32,1.52-2.24,2.89-2.24,.07,0,.13,0,.2,0h15.6c.07,0,.14,0,.21,0,1.39,0,2.58,.93,2.92,2.27l.77,2.31,.78,2.34v.04s.02,.04,.02,.04c.14,.6,.66,1.02,1.27,1.02,.04,0,.08,0,.12,0h.04s.04,0,.04,0h9.9s.11,0,.19,0c.89,0,1.77,.24,2.54,.69,1.32,.81,2.16,2.2,2.24,3.73,.02,.23,.02,.49,.02,.75v31.39Z"/>
                            <path fill="var(--primary)" d="M32.01,24.94h-.08c-6.33,.01-11.47,5.18-11.45,11.51,0,6.38,5.16,11.54,11.5,11.54,6.34-.01,11.51-5.17,11.54-11.5,0-3.09-1.19-5.98-3.36-8.16-2.17-2.18-5.06-3.38-8.14-3.38Zm-.06,21.22h-.01c-5.32-.05-9.65-4.41-9.65-9.73,.03-5.34,4.38-9.66,9.7-9.66h.06c5.32,.03,9.65,4.39,9.64,9.71-.04,5.34-4.4,9.68-9.73,9.68Z"/>
                        </svg>
                    </div>
                    <h4>{intl.formatMessage({id:"title-photos-pro", defaultMessage:"Photos professionnelles "})}</h4>
                    <p>
                        {intl.formatMessage({id: "home-paragraphe-3", defaultMessage : "Un photographe professionnel viendra prendre des photos pour mettre en valeur votre bien."})}
                    </p>
                </div>
                <div>
                    <div className="serviceIcone">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                            <path fill="var(--primary)" d="M7.18,52.72c4.75,0,9.45,0,14.16,0s9.41,0,14.11,0h.1c1.02,0,2.03-.25,2.93-.73,2.27-1.14,3.67-3.5,3.59-6.02,.01-3.12,0-6.21,0-9.3,0-1.55,0-3.1,0-4.64,0-4.68,0-9.36,0-14.04,0-.1,0-.19,0-.27-.09-3.61-3-6.44-6.61-6.44h-.18s-27.23,0-27.23,0c-.48,0-.96,.02-1.41,.05-3.43,.27-6.09,3.17-6.05,6.61v28.25c.02,3.61,2.98,6.54,6.58,6.54ZM2.92,17.95c-.08-1.32,.51-2.64,1.56-3.49,.67-.52,1.46-.8,2.29-.84,.13-.01,.28-.01,.44-.01,0,0,28.32,0,28.39,0,.78,0,1.54,.21,2.21,.61,1.1,.68,1.81,1.86,1.89,3.14,.02,.24,.02,23.99,.03,28.67,.04,.77-.14,1.57-.51,2.28-.68,1.25-1.97,2.05-3.39,2.08-.12,.01-.27,.01-.43,.01H7.28s-.13,0-.21,0c-.97,0-1.92-.33-2.68-.94-.8-.65-1.3-1.56-1.42-2.58-.03-.28-.04-19.57-.05-28.93Z"/>
                            <path fill="var(--primary)" d="M58.27,48.82c.48,.23,1,.35,1.55,.35,1.25,0,2.39-.64,3.05-1.7,.38-.64,.56-1.37,.54-2.12V18.65s0-.41,0-.41c-.02-1.14-.62-2.2-1.59-2.82-.61-.41-1.3-.62-2.01-.62-.66,0-1.31,.18-1.87,.53-3.71,2.06-7.4,4.09-11.09,6.13v2.62c4.11-2.24,8.22-4.49,12.33-6.74,.16-.1,.37-.16,.6-.16,.26,0,.52,.09,.72,.25,.39,.26,.62,.74,.58,1.24v26.71c0,.21-.03,.42-.1,.62-.02,.05-.04,.12-.07,.18-.21,.41-.62,.66-1.08,.66-.19,0-.38-.05-.55-.13-.09-.04-.21-.1-.32-.17l-12.1-6.62c0,.89,0,1.75-.02,2.61,1.39,.76,10.35,5.75,11.45,6.3Z"/>
                        </svg>
                    </div>
                    <h4>{intl.formatMessage({id:"title-visite-virtuelle", defaultMessage:"Visites virtuelles"})}</h4>
                    <p>
                        {intl.formatMessage({id:"home-paragraphe-4", defaultMessage:"Afin d’offrir une expérience en ligne immersive, nous proposons à nos clients de produire une visite virtuelle et un clip pour promouvoir votre propriété sur toutes les plateformes."})}
                    </p>
                </div>
                <div>
                    <div className="serviceIcone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                            <path d="M13.142,56.357c-3.913,0-5.374-5.027-5.4-8.408l2.717-.021c.016,2.421,1.047,5.8,2.683,5.8h0l9.524-.031q7.258-.025,14.517-.038a3.545,3.545,0,0,0,2.658-1.071c5.891-5.764,8.31-8.151,10.8-10.606,2.39-2.36,4.844-4.784,10.505-10.324a2.861,2.861,0,0,0-.026-4.561Q49.069,15.462,37,3.848c-1.7-1.638-3.3-1.617-5.014.064-5.524,5.4-7.948,7.792-10.26,10.073-2.49,2.458-4.848,4.788-10.806,10.6a3.488,3.488,0,0,0-1.153,2.722c.028,2.257.018,4.329.008,6.432-.008,1.483-.014,2.983-.008,4.575l-2.717.009c0-1.6,0-3.106.01-4.6.012-2.089.022-4.147,0-6.388A6.046,6.046,0,0,1,9,22.729c5.954-5.809,8.31-8.134,10.8-10.59,2.313-2.284,4.74-4.68,10.269-10.082,2.772-2.715,6.08-2.743,8.853-.079Q50.981,13.593,63.034,25.224a5.443,5.443,0,0,1,.039,8.282c-5.654,5.537-8.109,7.959-10.5,10.316-2.487,2.458-4.907,4.846-10.8,10.615a6.338,6.338,0,0,1-4.582,1.85q-7.255.006-14.509.039l-9.528.03Z" transform="translate(-0.963 0)" fill="#255d7c"/>
                            <path d="M4.924,43.816a10.616,10.616,0,0,1,5.682-1.625,5.4,5.4,0,0,0,5.277,4.1H15.9a5.468,5.468,0,0,0-.034-10.936h0a5.457,5.457,0,0,0-5.34,4.411,13.036,13.036,0,0,0-7,2.076A8.386,8.386,0,0,0,.073,48.111c-.4,3.941.877,7.165,3.607,9.076a9.341,9.341,0,0,0,5.312,1.641A8.585,8.585,0,0,0,12.73,58a5.729,5.729,0,0,0,3.4-4.879l-2.419-.139A3.325,3.325,0,0,1,11.67,55.82a6.817,6.817,0,0,1-6.6-.619c-1.4-.98-2.985-2.962-2.588-6.843a5.989,5.989,0,0,1,2.439-4.542M15.86,36.811h0a4.013,4.013,0,1,1,.03,8.026h-.01a3.962,3.962,0,0,1-3.766-2.652,23.087,23.087,0,0,1,3.8.437l.541-2.363a24.63,24.63,0,0,0-4.43-.5,3.991,3.991,0,0,1,3.834-2.944" transform="translate(0 5.172)" fill="#255d7c"/>
                        </svg>
                    </div>
                    <h4>{intl.formatMessage({id: "title-vente", defaultMessage: "Vente"})}</h4>
                    <p>
                        {intl.formatMessage({id: "home-paragraphe-5", defaultMessage : "Chaque pièce est une carte de visite. Votre bien est présenté sur les meilleurs sites immobiliers. Notre commercial saura faire de votre appartement, de votre chalet un coup de cœur."})}
                    </p>
                </div>
                <div>
                    <div className="serviceIcone">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                            <path fill="var(--primary)" d="M58.62,29.07h-.13c-1.07,0-43.99,0-43.99,0h-.12c-.82,0-1.65,.19-2.4,.54-1.05,.54-1.86,1.47-2.25,2.61l-5.74,14.34c-.22,.56-1.05,.4-1.05-.2,0,0,.04-32.34,.06-32.53,.06-.82,.33-1.61,.85-2.25,.81-.98,1.99-1.53,3.23-1.53,.08,0,13.12,0,13.24,0h0c.98,0,1.89,.36,2.6,1.02,.43,.42,.84,.83,1.25,1.24,.47,.47,.94,.93,1.41,1.39,.42,.43,.86,.84,1.31,1.21,1.82,1.44,4.11,2.24,6.44,2.24,0,0,13.02,0,13.2,.01,2.13,.06,3.83,1.79,3.84,3.95,.03,.83,.02,1.69,.02,2.52v.61s2.32,0,2.32,0c0-.19,0-.39,0-.58,0-.9,.02-1.75-.02-2.61-.04-1.16-.4-2.28-1.05-3.24-1.22-1.88-3.27-2.99-5.49-2.99,0,0-12.77,0-13.22,0-1.82-.01-3.57-.71-4.91-1.96-.93-.86-1.91-1.83-3.08-3.03-1.27-1.34-3.05-2.11-4.89-2.11-.06,0-.13,0-.19,0-4.14,.01-8.27,.01-12.4,.01-.45,0-.9,.04-1.35,.11-1.73,.26-3.29,1.22-4.28,2.65-.83,1.16-1.26,2.57-1.23,3.98,0,0,.01,35.21,.04,35.74,.06,1.09,.41,2.15,1.02,3.06,1.21,1.89,3.26,3.01,5.49,3.01,.06,0,44.19,0,44.19,0h.14c.81,0,1.62-.19,2.35-.54,1.04-.55,1.85-1.49,2.25-2.62l6.89-17.22c.3-.72,.44-1.47,.43-2.24-.02-1.83-1.12-3.49-3.06-4.28-.54-.22-1.13-.31-1.72-.3Zm2.21,5.83c-2.19,5.48-4.4,10.99-6.6,16.51-.16,.44-.36,.89-.58,1.32-.39,.76-1.16,1.23-2.01,1.23h-.38s-21.96,0-21.96,0c-2.63,0-5.26,0-7.89,0-4.71,0-9.41,0-14.12,0-.01,0-.06,0-.1,0-.94,0-1.81-.55-2.21-1.4-.32-.66-.32-1.46,0-2.12l3.54-8.86,3.38-8.44c.32-1.03,1.29-1.75,2.37-1.75,.07,0,.13,0,.2,0,5.4,0,34.75,0,44.03-.01h0c1.21,0,2.22,.78,2.52,1.91,.11,.56,.04,1.11-.19,1.59Z"/>
                        </svg>
                    </div>
                    <h4>{intl.formatMessage({id: "title-gestion-dossier", defaultMessage: "Gestion de dossier"})}</h4>
                    <p>
                        {intl.formatMessage({id: "home-paragraphe-6", defaultMessage: "Nous gérons le dossier de la prise de mandat au service après-vente, autant auprès du vendeur, que de l’acquéreur afin de garantir la satisfaction de toutes les parties concernées."})}
                    </p>
                </div>
                <div>
                    <div className="serviceIcone">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                            <path fill="var(--primary)" d="M63.08,9.02l-.02-.15c-.27-2.02-1.21-3.9-2.66-5.32-1.72-1.73-4.1-2.72-6.54-2.72-.23,0-.46,0-.69,.03-2.58,.11-5.01,1.28-6.7,3.2-3,3.31-20.21,22.49-23.93,26.62-.24,.25-.52,.43-.83,.52-1.18,.36-2.37,.72-3.55,1.08-1.82,.55-3.64,1.1-5.46,1.67-2.4,.67-4.26,2.47-5,4.82-1.77,5.26-3.53,10.61-5.23,15.78l-1.43,4.34c-.25,.67-.27,1.38-.07,2.03,.02,.06,.04,.12,.06,.18,.46,1.24,1.66,2.08,2.98,2.08,.38,0,.75-.07,1.1-.2,6.63-2.18,13.36-4.41,20.03-6.64,2.38-.74,4.2-2.61,4.88-4.99,.61-1.9,1.19-3.83,1.75-5.69,.33-1.11,.67-2.22,1.01-3.33,.11-.34,.3-.64,.55-.88,1.09-1,2.19-1.99,3.29-2.97,0,0,19.37-17.39,23.19-20.82,2.12-1.88,3.34-4.57,3.37-7.39-.02-.31-.05-.66-.07-1.02,0-.07-.02-.14-.03-.22ZM30.38,42.13v.04s-.02,.04-.02,.04c-.98,3.2-1.8,5.88-2.65,8.58-.48,1.64-1.76,2.92-3.42,3.4l-2.51,.82-13.75,4.45-.81-1.19s6-5.83,8.56-8.38c.32-.33,.77-.56,1.25-.62h.03s.03,0,.03,0c1.06-.07,1.91-.96,1.92-2.02,.04-.87-.42-1.66-1.18-2.03-.31-.15-.64-.23-.98-.23-.54,0-1.06,.2-1.47,.55-.38,.34-.61,.8-.65,1.32-.06,.55-.3,1.03-.68,1.39-2.57,2.6-8.35,8.42-8.35,8.42l-1.26-.52s3.73-11.36,5.38-16.4c.49-1.71,1.88-3.09,3.63-3.57,2.2-.65,4.44-1.34,6.61-2.01l1.75-.54c.14-.05,.29-.08,.44-.08,.36,0,.7,.15,.93,.42,2.3,2.34,4.59,4.68,6.88,7.03l.03,.03,.03,.03c.24,.3,.34,.7,.26,1.08ZM60.57,12.24c-.4,1.4-1.19,2.62-2.3,3.54-4.63,4.19-9.29,8.39-13.95,12.6l-11.55,10.42s-.09,.08-.14,.12l-.05,.04-.58,.5-.54-.54-6.4-6.41-.55-.55,.52-.58,.27-.3c.43-.47,.87-.97,1.31-1.46,3.3-3.66,6.6-7.32,9.91-10.97,3.9-4.31,7.81-8.63,11.71-12.95,.8-.96,1.86-1.7,3.06-2.14,.75-.27,1.54-.4,2.34-.4,1.33,0,2.63,.38,3.75,1.09,2.71,1.63,4.03,4.93,3.19,8Z"/>
                        </svg>
                    </div>
                    <h4>{intl.formatMessage({id: "title-accompagnement", defaultMessage: "Accompagnement administratif"})}</h4>
                    <p>
                        {intl.formatMessage({id: "home-paragraphe-7", defaultMessage: "Nos services de vente et d’administration vous accompagnent et prennent en charge votre projet immobilier de manière intégrale. Profitez de Champex et de ses environs, nous nous occupons de tout !"})}
                    </p>
                </div>
            </ThreeColumn>
        </Section>
        <Section id="expertise" className="expertise" background="var(--white)" color="var(--primary)">
            <TwoColumn>
                <div ref={section_2_image} className="left-box">
                    <ImageBox title={"Notre expertise"} image1={image3} image2={image4}/>
                </div>
                <div className="right-box">
                    <Textbox>
                        <p>
                            {intl.formatMessage({id: "home-paragraphe-8", defaultMessage: "En nous appuyant sur les meilleures pratiques actuelles et sur une connaissance pointue des marchés régionaux, nous établissons des rapports d’évaluation détaillés mettant en exergue les hypothèses retenues. Notre expérience et nos outils développés sur-mesure nous permettent de réaliser des expertises de tous les types de biens."})}
                            <br/>
                            <br/>
                            <strong>
                                {intl.formatMessage({id: "home-paragraphe-9", defaultMessage: "Préparez-vous à vivre l’expérience Au Club Immobilier"})}
                            </strong>
                        </p>
                        <ButtonTelNumber mail="contact@auclubimmobilier.ch " />
                    </Textbox>
                </div>
            </TwoColumn>
        </Section>
        </>
    );
}

export default Home;
