import React,{FunctionComponent, useRef, useState} from "react";
import { ArticleImages } from "../../types/articles";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { classNames } from "@ct-react/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ARTICLE_FOR_SALE } from "../../shared/fragments";
import { useQuery } from "@apollo/client";
import CardArticle, { CardArticleSkeleton } from "./card";

import "./carousel.scss";

type ArticleInfoCarouselProps = {
    isActive:boolean,
    setIsActive:React.Dispatch<React.SetStateAction<boolean>>,
    props:ArticleImages[],
    onClick: ()=>void
};

export const ArticleInfoCarousel:FunctionComponent<ArticleInfoCarouselProps> = ({isActive,setIsActive,props, onClick})=>{
    const [currentImgNumber, setCurrentImgNumber] = useState<number>(1);
    const images = [...props];

    const carousel:React.MutableRefObject<Splide|null> = useRef(null);

    const handleImageChange = ()=>{
        if ( carousel.current ) {
            const nbrOfImg = carousel.current.splide?.index;
            nbrOfImg!=undefined&&setCurrentImgNumber(nbrOfImg+1);
        }
    };

    const handleShowCarousel = ()=>{
        setIsActive(false);
    };

    return(
        <div
            className={classNames("article-image-carousel-block",{show:isActive})}
            onClick={()=>onClick()}
        >
            <Splide
                className="article-image-carousel"
                onMove={handleImageChange}
                ref={carousel}
                options={{
                    type:"loop",
                    arrows:false,
                    pagination:false,
                    mediaQuery: 'min',
                    start:0,
                    perPage:1,
                    perMove:1,
                    height:"40vh",
                    cover:true,
                    breakpoints:{
                        992:{
                            cover:false,
                            height:"40vw",
                            arrows:true,
                            keyboard:true
                        }
                    }
                }}
            >
                {images.map((img:any)=>(
                    <SplideSlide key={img.original}>
                        <img
                            srcSet={`${img.original} 480w, ${img.large} 1200w`}
                            sizes="(min-width: 960px) 480px,
                                    1200px"
                            src={img.large}
                            alt="Image du bien"
                        />
                    </SplideSlide>
                ))}
            </Splide>
            <FontAwesomeIcon className="article-image-carousel-close" size="2x" icon={faXmark} onClick={handleShowCarousel}/>
            <div className="article-image-carousel-current">
                {`${currentImgNumber} / ${images.length}`}
            </div>
        </div>
    );
};

export const ArticleCarouselSuggestions:FunctionComponent = ()=>{
    const [saleContent,setSaleContent] = useState<any|null>(null)
    const { loading, error } = useQuery(ARTICLE_FOR_SALE, {
        variables: {
            first:6,
            after:null,
            last:null,
            before:null,
            rooms:null,
            surface:null,
            price:null,
            objectTypes:null
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setSaleContent(data.search.edges)
        }
    });

    const skeletonSlide = ()=>{
        let cards = [];
        for (let i = 0; i < 3; i++) {
            cards.push(
            <SplideSlide key={i}>
                <CardArticleSkeleton/>
            </SplideSlide>
            );
        }
        return cards;
    };

    if(error){//Must do 404
        return <></>;
    }else{
        return(
            <Splide
                className="articlesCarousel"
                hasTrack={false}
                aria-label="Images"
                options={
                    {
                        type:"loop",
                        breakpoints: {
                            992: {
                                destroy: true,
                            },
                            1050:{
                                perPage:2,
                            },
                            1450:{
                                perPage:2,
                            }
                        },
                        perPage:3,
                        perMove:1,
                        autoplay:true,
                        pauseOnHover:true,
                        speed:800,
                        interval:2500,
                        start:0,
                        gap:"100px",
                        padding:"16px",
                        pagination:false,
                        rewind:true,
                        arrowPath:"M39.56,18.94L27.4,6.78c-0.58-0.58-1.53-0.58-2.12,0c-0.58,0.58-0.58,1.53,0,2.12l9.61,9.61H1.5C0.67,18.5,0,19.17,0,20 s0.67,1.5,1.5,1.5h33.39l-9.61,9.61c-0.58,0.58-0.58,1.53,0,2.2c0.29,0.29,0.68,0.44,1.06,0.44s0.77-0.15,1.06-0.44l12.16-12.16 C39.84,20.78,40,20.4,40,20C40,19.6,39.84,19.22,39.56,18.94z",
                        classes: {
                            arrows: 'splide__arrows carousel-arrowsBlock',
                            arrow : 'splide__arrow carousel-arrow',
                            prev  : 'splide__arrow--prev carousel-arrowPrev',
                            next  : 'splide__arrow--next carousel-arrowNext',
                            pagination:"splide__pagination carousel-pagination",
                        }
                }}
            >
                <SplideTrack>
                    {!loading && !!saleContent ?
                    saleContent.map((i:any)=>{
                        return(
                            <SplideSlide key={i.node.id}>
                                <CardArticle key={i.node.id} props={i.node}/>
                            </SplideSlide>
                        )
                    })
                    :
                    skeletonSlide()
                    }
                </SplideTrack>
            </Splide>
        );
    }

};
