import React, {FormEvent, FunctionComponent, useEffect, useMemo, useReducer, useState} from "react";
import { useIntl } from "react-intl";
import contactFormReducer from "../../reducer/forms.reducer";
import defaultFormData from "../../../types/forms.data";
import { useMutation } from "@apollo/client";
import { EMAIL_MUTATION } from "../../../shared/fragments";
import { FormButton } from "../common";
import Loader from "../../layout/loader";

import "./contactForm.scss";
import { FormCallback } from "./form-callback";

const initialFormData = new defaultFormData();

const ContactForm:FunctionComponent = () => {
    const intl = useIntl();
    const [formData, dispatchFormData] = useReducer<(formData: any, action: any) => any>(contactFormReducer, initialFormData);
    const [isDisabled,setIsDisabled] = useState<boolean>(true);
    const [succeeded, setsucceeded] = useState<boolean|null>(null);

    const [ sendEmail, { loading: actionLoading } ] = useMutation(EMAIL_MUTATION);

    const handleSubmit = ()=>{
        dispatchFormData({
            type: "reset",
        });
        sendEmail({
            variables:{
                firstName : formData.surname,
                lastName : formData.name,
                email : formData.email,
                phone : formData.tel,
                body : formData.message
            }
        })
        .then(() => setsucceeded(true))
        .catch(() => setsucceeded(false) );

    };

    const handleFormData = (e:FormEvent)=>{
        dispatchFormData({
            //@ts-ignore
            type: e.currentTarget.name,
            //@ts-ignore
            value: e.currentTarget.value,
          });
        if(Object.values(formData).includes(false)){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    };

    const formCallback = useMemo(()=>{
        if(!!succeeded) return <FormCallback setState={()=>setsucceeded(null)} succeeded={succeeded}/>
        return undefined;
    },[succeeded])

    useEffect(()=>{
        if(Object.values(formData).includes(false)){
            setIsDisabled(true)
        }else{
            setIsDisabled(false)
        }
    },[formData]);

    return(
        <>
            {actionLoading&& <Loader/>}
            {formCallback}
            <form className="contactForm">
                <div className="input-block">
                    <label htmlFor="name">{intl.formatMessage({id: "form-label-nom", defaultMessage: "Nom"})}*</label>
                    <input type="text" name="name" id="name" required onInput={handleFormData} />
                    <span>{formData.name===""||!formData.nameValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block">
                    <label htmlFor="surname">{intl.formatMessage({id: "form-label-prenom", defaultMessage: "Prénom"})}*</label>
                    <input type="text" name="surname" id="surname" required onInput={handleFormData} />
                    <span>{formData.surname===""||!formData.surnameValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block">
                    <label htmlFor="email">{intl.formatMessage({id: "form-label-email", defaultMessage: "Email"})}*</label>
                    <input type="email" name="email" id="email" required onInput={handleFormData} />
                    <span>{formData.email===""||!formData.emailValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="input-block">
                    <label htmlFor="tel">{intl.formatMessage({id: "form-label-telephone", defaultMessage: "Téléphone"})}*</label>
                    <input type="tel" name="tel" id="tel" required onInput={handleFormData} />
                    <span>{formData.tel===""||!formData.telValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <div className="textarea-block">
                    <label htmlFor="message">{intl.formatMessage({id: "form-label-message", defaultMessage: "Message"})}*</label>
                    <textarea name="message" id="message" cols={30} rows={10} onInput={handleFormData} required ></textarea>
                    <span>{formData.message===""||!formData.messageValid&& intl.formatMessage({id:"form-span-field-not-valid", defaultMessage: "*Champ non valide"})}</span>
                </div>
                <FormButton value={intl.formatMessage({ id:"btn-send", defaultMessage: "Envoyer"})} disabled={isDisabled} onSubmit={()=>handleSubmit()}/>
            </form>
        </>
    );
};

export default ContactForm;
