import fetch from "isomorphic-fetch";
import { Locale } from "@ct-react/locale";
import {ApolloClient, ApolloClientOptions, ApolloLink, concat, createHttpLink, HttpLink, InMemoryCache} from "@apollo/client";

export const APOLLO_CACHE_SCRIPT_ID = "apollo-state";

const buildConsumerMiddleware = (locale: Locale) => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        "GQL-Consumer-Lang": locale.basename,
        "GQL-Consumer-AppName":"club-immo"
      }
    }));
    return forward(operation);
  });
}

const buildApolloClient = (locale:Locale,ssrMode: boolean = false, restoringCache?: any) => {
  const cache = new InMemoryCache();//Cache instance
  if (!!restoringCache) cache.restore(JSON.parse(restoringCache));
  //for client side, rehydrate cache using the initial data passed from the server

  return new ApolloClient({
    connectToDevTools: true,
    link: concat(
      buildConsumerMiddleware(locale),
      ssrMode
        ? createHttpLink({ uri: process.env.GQL_CLIENT_URL, fetch })
        : new HttpLink({ uri: process.env.GQL_CLIENT_URL })
    ),
    cache
  } as unknown as ApolloClientOptions<InMemoryCache>);
}

export default buildApolloClient;