import { SearchAccomodationArticles } from "../../types/searchArticles";

type Action = {
    direction:string;
    id:string;
    minRooms:string;
    price:string;
    minSurface:string;
    minGuests:string;
    objectTypes:string,
    regions:number
}


export default function articlesPaginationReducer(searchData:SearchAccomodationArticles,action:Action){
    if(action.direction==="before"){
        return (
            {
                ...searchData,
                before:action.id,
                last:8,
                after:null,
                first:null,
            }
        )
    }else if(action.direction==="after"){
        return (
            {
                ...searchData,
                after:action.id,
                first:8,
                before:null,
                last:null,
            }
        )
    }else{
        return (
            {
                ...searchData,
                after:null,
                first: 8,
                before:null,
                last: null,
                where:{
                    ...searchData.where,
                    minRooms:!!action.minRooms?parseInt(action.minRooms):null,
                    price: !!action.price? {
                        from: parseInt(action.price.split("-")[0]),
                        to : parseInt(action.price.split("-")[1])
                    } : null,
                    minSurface:!!action.minSurface?parseInt(action.minSurface):null,
                    objectTypes:!!action.objectTypes?action.objectTypes.split("-"):null,
                    regions:!!action.regions? action.regions : null
                }
            }
        );
    }
}