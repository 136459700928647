import React, { FunctionComponent } from 'react';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const SearchModuleComponent = loadable(() => import('../../layout/searchModule/searchModule'));

type SearchModuleProps ={
  isVisible:boolean,
  setIsVisible: (isVisible: boolean) => void
};

export const SearchModule:FunctionComponent<SearchModuleProps> = ({isVisible, setIsVisible}) => {
  return (
      <SearchModuleComponent isVisible={isVisible} setIsVisible={setIsVisible} />
  )
};

export const MapContainer = loadable(
  () => import(/* webpackChunkName: "leaflet-map" */"@ct-react/map"),
  { ssr: false });

export const MapMarker = loadable(
  () => import(/* webpackChunkName: "leaflet-map" */"@ct-react/map"),
  {
    ssr: false,
    resolveComponent: module => module.Marker
});

export const MapPopup = loadable(
  () => import(/* webpackChunkName: "leaflet-map" */"@ct-react/map"),
  {
    ssr: false,
    resolveComponent: module => module.Popup  
});

export const HomeMarker =()=>{
  return(
    <div className="homeMarker">
      <FontAwesomeIcon icon={faHome}/>
    </div>
  )
};

export const MapAsyncPopupMarker = loadable(
  () => import(/* webpackChunkName: "map-implement" */"../maps/async-popup-marker"),
  { ssr: false });

  