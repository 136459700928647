import React,{FunctionComponent, useEffect} from "react";
import { gsap } from "gsap";
import "./loader.scss";

const Loader:FunctionComponent = ()=>{
    useEffect(()=>{
        const loader = document.getElementById("loaderBox")
        setTimeout(()=>{
            gsap.to(loader,{
                autoAlpha:0,
                duration:0.8
            })
        },2000)
    },[])
    return(
        <div id="loaderBox" className="loaderBox">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 110.07 69.67">
                <g>
                    <g transform="translate(0 56.277)">
                        <g>
                            <path fill="var(--white)" d="M1.83,13.12H0V0.27h1.84L1.83,13.12z"/>
                            <path fill="var(--white)" d="M8.4,0l4.17,9.32L16.74,0l3.82,13.12h-1.84l-2.38-8.28l-3.79,8.55L8.78,4.84l-2.38,8.28H4.57
                                L8.4,0z"/>
                            <path fill="var(--white)" d="M25.84,0l4.17,9.32L34.18,0L38,13.12h-1.84l-2.38-8.28l-3.79,8.55l-3.78-8.55l-2.38,8.28
                                h-1.83L25.84,0z"/>
                            <g transform="translate(39.693)">
                                <path fill="var(--red)" d="M11.35,4.29c-0.38-0.8-0.98-1.47-1.73-1.94C8.82,1.84,7.88,1.59,6.93,1.6
                                    C5.58,1.56,4.27,2.1,3.34,3.09c-1.85,2.06-1.85,5.18,0,7.24c1.99,1.95,5.17,1.95,7.16,0c0.92-0.97,1.42-2.27,1.39-3.61
                                    C11.9,5.88,11.71,5.04,11.35,4.29z"/>
                                <path fill="var(--white)" d="M12.12,2.18c-0.59-0.7-1.34-1.24-2.18-1.59C9,0.19,7.98-0.01,6.95,0
                                    c-1.86-0.07-3.66,0.62-5,1.91C0.66,3.16-0.05,4.9,0,6.7c-0.06,1.77,0.64,3.47,1.92,4.69c1.32,1.35,3.14,2.07,5.02,2.01
                                    c3.68,0.13,6.76-2.75,6.89-6.43c0-0.09,0-0.18,0-0.27C13.83,5.03,13.22,3.43,12.12,2.18z M6.93,11.79
                                    c-1.35,0.05-2.66-0.48-3.59-1.46c-1.85-2.06-1.85-5.18,0-7.24C4.26,2.1,5.57,1.56,6.93,1.61c0.95-0.02,1.88,0.24,2.69,0.74
                                    c0.75,0.47,1.35,1.14,1.73,1.94c0.37,0.76,0.55,1.59,0.54,2.43c0.03,1.34-0.47,2.63-1.39,3.61C9.57,11.3,8.27,11.84,6.93,11.79
                                    L6.93,11.79z"/>
                            </g>
                            <path fill="var(--white)" d="M60.71,13.12h-4.21V0.27h3.53c2.98,0,4.48,1.09,4.48,3.26c-0.04,1.27-0.88,2.37-2.1,2.74
                                v0.06c0.78,0.11,1.49,0.48,2.02,1.06c0.48,0.54,0.74,1.23,0.73,1.95c0.05,1.05-0.37,2.06-1.15,2.77
                                C63.07,12.84,61.9,13.2,60.71,13.12z M58.34,1.87v3.82h1.91c1.54,0,2.32-0.66,2.32-1.97c0.03-0.52-0.2-1.03-0.61-1.36
                                C61.43,2,60.79,1.83,60.15,1.87H58.34z M58.34,7.3v4.22h2.1c1.84,0,2.76-0.72,2.76-2.16c0-1.38-0.9-2.07-2.7-2.06H58.34z"/>
                            <path fill="var(--white)" d="M69.95,13.12h-1.83V0.27h1.83V13.12z"/>
                            <path fill="var(--white)" d="M81.86,11.52v1.6h-7.89V0.27h1.83v11.25L81.86,11.52L81.86,11.52z"/>
                            <path fill="var(--white)" d="M85.43,13.12h-1.84V0.27h1.83L85.43,13.12z"/>
                            <path fill="var(--white)" d="M91.27,5.73h5.46v1.6h-5.46v4.19h6.06v1.6h-7.89V0.28h7.84v1.6h-6L91.27,5.73z"/>
                            <path fill="var(--white)" d="M103.11,7.72h-0.81v5.4h-1.84V0.27h4.32c1.1-0.05,2.17,0.3,3.03,0.98
                                c0.79,0.64,1.23,1.62,1.18,2.64c0,2.13-1.24,3.39-3.71,3.76l4.79,5.46h-2.28L103.11,7.72z M104.24,1.87h-1.95v4.25h1.93
                                c0.74,0.05,1.48-0.15,2.09-0.58c0.49-0.39,0.76-1,0.73-1.62C107.04,2.55,106.11,1.87,104.24,1.87L104.24,1.87z"/>
                        </g>
                    </g>
                    <g transform="translate(24.028)">
                        <g transform="translate(0)">
                            <path fill="var(--white)" d="M13.74,43.82c-0.41,0.34-0.86,0.63-1.35,0.86c-0.46,0.22-0.95,0.4-1.45,0.51
                                c-1.55,0.35-3.17,0.19-4.62-0.45c-0.91-0.38-1.74-0.95-2.42-1.68c-0.67-0.73-1.2-1.58-1.54-2.52c-0.72-2.03-0.72-4.24,0-6.26
                                c0.34-0.93,0.86-1.79,1.54-2.52c0.68-0.72,1.5-1.29,2.42-1.68c1.88-0.81,4-0.82,5.89-0.02c0.88,0.39,1.63,1.04,2.16,1.85
                                l0.04,0.06l1.49-1.24l-0.04-0.05c-0.74-0.97-1.73-1.74-2.85-2.21c-1.13-0.44-2.34-0.66-3.56-0.65c-1.3-0.01-2.59,0.24-3.79,0.72
                                c-1.13,0.46-2.15,1.14-3,2c-0.84,0.87-1.51,1.9-1.95,3.04C0.23,34.8-0.01,36.1,0,37.42c-0.01,1.32,0.23,2.63,0.7,3.86
                                c0.44,1.14,1.1,2.17,1.95,3.05c0.85,0.86,1.87,1.54,3,1.99c1.23,0.48,2.54,0.73,3.86,0.71c1.3,0.01,2.58-0.25,3.77-0.78
                                c1.23-0.57,2.29-1.46,3.07-2.56l0.04-0.05l-1.5-1.13l-0.04,0.05C14.55,43.04,14.17,43.46,13.74,43.82z"/>
                            <path  fill="var(--white)" d="M42.63,16.48c-1.59,0.81-3.48,0.81-5.07,0c-0.61-0.38-1.12-0.91-1.48-1.54
                                c-0.35-0.63-0.59-1.32-0.7-2.03c-0.12-0.68-0.18-1.37-0.18-2.07V0h-1.83v11.27c0,0.95,0.11,1.89,0.34,2.81
                                c0.21,0.86,0.6,1.67,1.14,2.38c0.55,0.71,1.27,1.28,2.09,1.66c2.03,0.83,4.3,0.83,6.33,0c0.82-0.38,1.53-0.95,2.09-1.66
                                c0.54-0.71,0.92-1.52,1.14-2.38c0.23-0.92,0.34-1.86,0.34-2.81V0h-1.83v10.83c0,0.69-0.06,1.38-0.18,2.07
                                c-0.11,0.71-0.35,1.4-0.7,2.03C43.76,15.56,43.25,16.09,42.63,16.48z"/>
                            <path fill="var(--white)" d="M21.2,28.27h-1.83v18.29h10.35v-1.67H21.2V28.27z"/>
                            <path fill="var(--white)" d="M31.23,18.29L23.48,0h-1.84l-8.04,18.29h1.92l6.95-16.21l6.01,14.55h-8.31l-0.73,1.66
                                L31.23,18.29z"/>
                            <path fill="var(--white)" d="M60.86,38.26c-0.37-0.39-0.82-0.71-1.31-0.93c-0.07-0.04-0.15-0.07-0.22-0.09l-0.82-0.33
                                l0.78-0.42c0.42-0.22,0.79-0.53,1.09-0.89c0.56-0.69,0.86-1.56,0.84-2.45c0.04-0.87-0.15-1.73-0.54-2.51
                                c-0.33-0.6-0.81-1.11-1.39-1.48c-0.59-0.36-1.24-0.6-1.92-0.7c-0.69-0.11-1.4-0.17-2.1-0.17h-5.33v18.29h5.9
                                c0.68-0.01,1.35-0.08,2.01-0.21c0.71-0.14,1.39-0.4,2.01-0.78c0.63-0.39,1.16-0.93,1.55-1.57c0.44-0.79,0.65-1.68,0.62-2.59
                                c0.01-0.6-0.1-1.2-0.3-1.77C61.53,39.14,61.24,38.66,60.86,38.26z M51.76,29.94h3.71c0.51,0,1.01,0.05,1.51,0.15
                                c0.44,0.09,0.87,0.26,1.24,0.51c0.36,0.25,0.65,0.59,0.85,0.98c0.23,0.48,0.33,1.01,0.32,1.55c-0.01,0.32-0.06,0.64-0.15,0.94
                                c-0.12,0.37-0.32,0.7-0.6,0.96c-0.37,0.34-0.8,0.6-1.27,0.77c-0.68,0.23-1.4,0.34-2.12,0.32h-3.47L51.76,29.94z M60.02,42.54
                                c-0.12,0.42-0.34,0.8-0.64,1.12c-0.39,0.39-0.86,0.7-1.38,0.88c-0.77,0.26-1.58,0.38-2.39,0.35h-3.84v-7.11h4.09
                                c0.53,0,1.06,0.07,1.57,0.2c0.5,0.13,0.97,0.35,1.39,0.65c0.41,0.3,0.75,0.69,0.99,1.15c0.27,0.52,0.4,1.1,0.38,1.69
                                C60.18,41.83,60.12,42.19,60.02,42.54L60.02,42.54z"/>
                            <path fill="var(--white)" d="M41.2,44.75c-1.59,0.81-3.48,0.81-5.07,0c-0.61-0.38-1.12-0.91-1.48-1.54
                                c-0.35-0.63-0.58-1.32-0.7-2.03c-0.12-0.68-0.18-1.37-0.18-2.07V28.27h-1.83v11.27c0,0.95,0.11,1.89,0.34,2.81
                                c0.21,0.86,0.6,1.68,1.14,2.38c0.56,0.71,1.27,1.28,2.09,1.66c2.03,0.83,4.3,0.83,6.33,0c0.82-0.38,1.53-0.95,2.09-1.66
                                c0.54-0.71,0.92-1.52,1.13-2.38c0.23-0.92,0.34-1.86,0.34-2.81V28.27h-1.83v10.83c0,0.69-0.07,1.38-0.18,2.07
                                c-0.11,0.71-0.35,1.4-0.7,2.03C42.32,43.84,41.81,44.37,41.2,44.75z"/>
                        </g>
                    </g>
                </g>
            </svg>
            <div className="loader">
                <div></div>
            </div>
        </div>
    )
}

export default Loader;