import React,{ createContext, Dispatch, ReactNode, SetStateAction, useMemo, useReducer, useState} from "react";
import { SearchData } from "../../types/searchData";
import searchDataReducer from "../reducer/search-module.reducer";

type SearchDataContextProps = {
    isVisible:boolean,
    setIsVisible:Dispatch<SetStateAction<boolean>>,
    searchData:SearchData,
    dispatchSearchData:React.Dispatch<any>
}

type SearchDataContextProviderProps = {
    state:boolean;
    children:ReactNode
}

export const SearchDataContext = createContext<SearchDataContextProps>({
    isVisible:false,
    setIsVisible: () => void {},
    searchData: new SearchData(),
    dispatchSearchData:() => void {}
});

export const SearchDataProvider = ({state,children}:SearchDataContextProviderProps)=>{
    const [searchData, dispatchSearchData] = useReducer<(searchData: SearchData, action: any) => any>(searchDataReducer, new SearchData());
    const [isVisible,setIsVisible] = useState<boolean>(state)
    const value = useMemo(()=>{
        return {isVisible,setIsVisible,searchData,dispatchSearchData}
    },[isVisible,searchData])
    return(
        <SearchDataContext.Provider value={value}>
            {children}
        </SearchDataContext.Provider>
    )
}

