import defaultFormData  from "../../types/forms.data";

type Action={
    type:string;
    value:any;
}

const emailChain = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const textChain = /[^a-zéàèüûúùöôõóòäêâîïëçñA-ZÂÀÁÄÃÜÛÚÙÊËÉÈÔÓÒÖÕîïíì\s']/gmu;
const telChain = /[^+0-9]/gm;
const textareaChain = /script/gm;

export default function contactFormReducer(formData:defaultFormData, action:Action) {
    switch (action.type) {
        case ("reset"):{
            return(
                {
                    ...formData,
                    name:"",
                    nameValid:false,
                    surname:"",
                    surnameValid:false,
                    email:"",
                    emailValid:false,
                    tel:"",
                    telValid:false,
                    subject:"",
                    message:"",
                    messageValid:false,        
                }
            )
        }
        case ("name"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        name:action.value,
                        nameValid:true,
                    }
                )
            }
        }
        case ("surname"):{
            if(textChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        surname:action.value,
                        surnameValid:true
                    }
                )
            }
        }
        case ("email"):{
            if(!emailChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        email:action.value,
                        emailValid:true
                    }
                )
            }
        }
        case ("tel"):{
            if(telChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        tel:action.value,
                        telValid:true,
                    }
                )
            }
        }
        case ("subject"):{
            return(
                    {
                        ...formData,
                        subject:action.value
                    }
                )
        }
        case ("message"):{
            if(textareaChain.test(action.value)||action.value===""){
                return(
                    {
                        ...formData,
                        message:action.value,
                        messageValid:false
                    }
                )
            }else{
                return(
                    {
                        ...formData,
                        message:action.value,
                        messageValid:true,
                    }
                )
            }
        }
        case ("contactType"):{
            return(
                {
                    ...formData,
                    contactType:action.value,
                }
            )
        }
        default:{
            throw Error('Unknown action: ' + action.type);
        }
    }
}
