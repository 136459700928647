import React,{FunctionComponent, useMemo} from "react";
import { useIntl } from "react-intl";
import { formatPrice } from "../../shared/utils";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Skeleton from "react-loading-skeleton";
import { LocaleLink } from "@ct-react/locale";

import "./card.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

type CardArticleProps = {
    props:any
};

const CardArticle:FunctionComponent<CardArticleProps> = ({props}) => {
    const intl = useIntl();
    if(props.id === undefined) return <></>;
    
    const renderPrice = useMemo(()=>{
        if(props.priceOnDemand) return(<span >{intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"})}</span>);
        if(!!props.price.amount) return(<span >{props.price.currency} {formatPrice(props.price.amount)}.-</span>);
        return (<span>{intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"})}</span>)
    
    },[props]);

    const rooms = ()=>{
        const room = props.features.rooms;
        if(room===null){
            return "";
        }else if(room === 1){
            return intl.formatMessage({id:"card-article-studio", defaultMessage: "Studio"}) + " | ";
        }else if(room > 1){
            return intl.formatMessage(
                {id:"card-article-nbrRoom", defaultMessage: "{rooms} pièces"},
                {rooms : props.features.rooms}
            ) + " | ";
        }else{            
            return "";
        }
    };

    return(
        <article className="card">
            {
                props.features.isSold && <div className="chip-sale">{intl.formatMessage({id:"sold", defaultMessage : "Vendu"})}</div>
            }
            <div className="card-carousel">
                { props.images.length>0 ? 
                    <Splide aria-label="Images" options={{
                        type:"slide",
                        height:"14em",
                        cover:true,
                        drag:false,
                        start:0,
                        pagination:false,
                        rewind:true,    
                        classes: {
                            arrows: 'splide__arrows cardImage-arrowsBlock',
                            arrow : 'splide__arrow cardImage-arrow',
                        },
                
                    }}>
                        {props.images.map((img:any)=>{
                            return(
                                <SplideSlide key={img.medium}>
                                    <img src={img.medium} alt="alternative text"/>
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                :
                    <div className="emptyImg">
                        <FontAwesomeIcon icon={faCamera}/>
                        <p>
                            {intl.formatMessage({id:"no-image", defaultMessage: "Aucune image disponible"})}
                        </p>
                    </div>
                }
            </div>
            <div className="card-info">
                <div className="card-room">{rooms()} {props.features.livingSpace} m² | {props.location.city}</div>
                <h5 className="card-title">{props.title.value}</h5>
                <div className="card-equipment sale">
                    {props.features.parkings > 0 && 
                    <>
                        <div className="card-icone">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.8 52.8">
                                <path fill="var(--black)" d="M15.76,31.28L15.76,31.28l-6.41-1.54c-0.09-0.02-0.19-0.03-0.28-0.03c-0.21,0-0.42,0.06-0.6,0.17
                                    c-0.26,0.16-0.45,0.42-0.52,0.73c-0.07,0.3-0.02,0.61,0.14,0.88c0.16,0.26,0.42,0.45,0.72,0.52l6.42,1.54
                                    c0.08,0.02,0.18,0.03,0.27,0.03c0.54,0,1.01-0.37,1.13-0.89C16.77,32.06,16.38,31.43,15.76,31.28z"/>
                                <path fill="var(--black)" d="M55.33,29.88c-0.18-0.11-0.39-0.17-0.6-0.17c-0.09,0-0.18,0.01-0.28,0.03l0,0l-6.41,1.54
                                    c-0.62,0.15-1.01,0.78-0.87,1.4c0.12,0.52,0.6,0.89,1.13,0.89c0.08,0,0.18-0.01,0.27-0.03L55,32.01c0.62-0.15,1-0.78,0.86-1.4
                                    C55.78,30.3,55.59,30.05,55.33,29.88z"/>
                                <path fill="var(--black)" d="M52.48,20.63c0-0.64-0.52-1.17-1.15-1.17H12.5c-0.64,0-1.16,0.52-1.16,1.17c0,0.64,0.52,1.16,1.16,1.16h38.83
                                    C51.96,21.79,52.48,21.27,52.48,20.63z"/>
                                <path fill="var(--black)" d="M62.83,26.2c-0.23-0.55-0.49-1.18-0.78-1.89c-0.56-1.36-1.22-2.96-1.93-4.65l-0.06-0.14h2.11
                                    c0.64,0,1.16-0.52,1.16-1.17c0-0.64-0.52-1.16-1.16-1.16h-3.08l-0.03-0.06c-1.21-2.91-2.55-6.13-3.69-8.84
                                    c-1.43-3.4-4.41-5.89-7.97-6.66l-0.28-0.06c-10.01-2.1-20.16-2.1-30.17,0l-0.28,0.06c-3.56,0.76-6.53,3.25-7.96,6.65
                                    c-1.12,2.65-2.46,5.88-3.69,8.84L5.01,17.2H1.16C0.52,17.2,0,17.72,0,18.36c0,0.64,0.52,1.17,1.16,1.17h2.87l-0.06,0.14
                                    c-0.96,2.33-1.85,4.47-2.51,6.06c-0.28,0.66-0.51,1.22-0.69,1.66l-0.08,0.21c-0.29,0.7-0.42,1.45-0.39,2.18v20.64
                                    c0,1.32,1.07,2.39,2.38,2.39h9.78c1.31,0,2.38-1.07,2.38-2.39v-6.08l0.11,0.02c5.05,0.72,10.8,1.08,17.09,1.08
                                    c6.29,0,12.04-0.38,17.1-1.12l0.11-0.02v6.12c0,1.32,1.07,2.39,2.39,2.39h9.78c1.31,0,2.38-1.07,2.38-2.39V29.83
                                    c0.03-0.78-0.11-1.53-0.4-2.25L62.83,26.2z M12.46,50.47l-9.84,0.04v-9.48l0.15,0.1c0.03,0.02,0.07,0.04,0.1,0.07l0.12,0.08
                                    c0.18,0.12,0.36,0.23,0.56,0.31c1.27,0.52,4.11,1.53,8.9,2.37l0.08,0.01v0.08l0.03,6.42H12.46z M32.04,43.11
                                    c-16.83,0-24.92-2.57-27.62-3.67C3.4,39.01,2.67,38,2.62,36.91v-7.2c-0.02-0.43,0.05-0.85,0.22-1.24l0.27-0.66
                                    c0.4-0.96,0.96-2.31,1.62-3.9c1.84-4.44,4.37-10.53,6.13-14.72c1.13-2.7,3.49-4.68,6.31-5.28l0.27-0.06
                                    c9.56-2.01,19.66-2.01,29.21,0l0.28,0.06c2.82,0.61,5.18,2.58,6.31,5.28c2.22,5.29,5.7,13.69,7.37,17.73l0.65,1.55
                                    c0.17,0.43,0.24,0.86,0.21,1.31l0.01,6.99c-0.05,1.14-0.76,2.16-1.81,2.58C56.91,40.48,48.7,43.11,32.04,43.11z M61.42,50.47
                                    l-9.85,0.04v-6.59l0.08-0.01c4.73-0.85,7.61-1.87,8.9-2.4c0.21-0.09,0.41-0.21,0.59-0.34c0.04-0.03,0.08-0.05,0.11-0.07
                                    c0.03-0.02,0.05-0.04,0.08-0.05l0.15-0.1v0.18l0.03,9.34H61.42z"/>
                            </svg>
                            {props.features.parkings} {props.features.parkings>1 ? intl.formatMessage({id:"card-article-parking-places", defaultMessage: "Places"}) : intl.formatMessage({id:"card-article-parking-place", defaultMessage: "Place"})}
                        </div>
                    </>
                    }
                    {props.features.bathrooms && 
                    <>
                        <div className="card-icone">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.3 55.61">
                                <path fill="var(--black)" d="M56.03,25.01H45.8v-2.84H29v2.84H6.2V8.35C6.2,5.95,8.15,4,10.55,4c0.81,0,1.59,0.24,2.27,0.66l-0.46,0.48
                                c-1.49,1.52-2.32,3.55-2.32,5.68v0.45c0,1.83,0.72,3.59,2,4.9l1.4,1.42L26.72,3.4l-0.77-0.78l-0.56-0.57C24.11,0.74,22.35,0,20.51,0
                                h-0.12c-1.91,0-3.75,0.77-5.09,2.13l-0.84,0.85c-1.13-0.82-2.49-1.28-3.91-1.28C6.88,1.7,3.9,4.68,3.9,8.35v16.69
                                C1.72,25.24,0,27.05,0,29.28c0,2.23,1.72,4.04,3.9,4.24v7.03c0,5.35,3.95,9.76,9.09,10.51l-1.22,3.73l2.18,0.72l1.42-4.32h29.08
                                l1.93,4.43l2.11-0.92l-1.59-3.65c5.07-0.81,8.94-5.19,8.94-10.49v-6.99h0.19c2.35,0,4.27-1.91,4.27-4.28
                                C60.3,26.92,58.38,25.01,56.03,25.01z M15.36,5.36l1.58-1.62c0.91-0.93,2.16-1.45,3.45-1.45h0.12c1.12,0,2.18,0.43,3.01,1.17
                                L13.43,14.24c-0.69-0.84-1.09-1.88-1.09-2.97v-0.45c0-1.52,0.6-2.98,1.66-4.07L15.36,5.36z M31.3,32.41v-7.94h12.2v16.46H31.3V32.41
                                z M4.28,31.26c-1.09,0-1.98-0.89-1.98-1.98c0-1.09,0.89-1.98,1.98-1.98H29v3.95H5.05H4.28z M53.54,40.55c0,4.6-3.73,8.33-8.33,8.33
                                H14.53c-4.59,0-8.33-3.73-8.33-8.33v-6.99H29v9.66h16.8v-9.66h7.74V40.55z M56.03,31.26h-1.34H45.8v-3.95h10.23
                                c1.08,0,1.97,0.89,1.97,1.98C58,30.37,57.11,31.26,56.03,31.26z"/>
                            </svg>
                            {props.features.bathrooms} {props.features.bathrooms>1 ? intl.formatMessage({id:"card-article-nbr-bathrooms", defaultMessage:"Salles de bains"}) : intl.formatMessage({id:"bathroom", defaultMessage: "Salle de bain"})}
                        </div>
                    </>
                    }
                    {props.features.bedrooms && 
                    <>
                        <div className="card-icone">
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 53" >
                                <path fill="var(--black)" d="M58.56,24.18l-0.08-0.07V5c0-2.63-2.14-4.78-4.78-4.78H10.3C7.66,0.22,5.52,2.37,5.52,5v19.11l-0.08,0.07
                                    c-3.31,2.79-5.22,6.87-5.22,11.21v16.21c0,0.64,0.52,1.17,1.17,1.17c0.64,0,1.16-0.52,1.16-1.17v-1.97h58.9v1.97
                                    c0,0.64,0.52,1.17,1.16,1.17c0.64,0,1.17-0.52,1.17-1.17V35.4C63.78,31.06,61.87,26.97,58.56,24.18z M7.84,5
                                    c0-1.35,1.1-2.45,2.45-2.45h43.4c1.35,0,2.45,1.1,2.45,2.45v17.55l-0.33-0.17c-1.6-0.82-3.3-1.32-5.03-1.49l-0.2-0.02v-4.2
                                    c0-2.7-2.2-4.9-4.9-4.9h-6.44c-2.7,0-4.9,2.2-4.9,4.9v4.15h-4.63v-4.15c0-2.7-2.2-4.9-4.9-4.9h-6.44c-2.7,0-4.9,2.2-4.9,4.9v4.19
                                    l-0.2,0.02c-1.78,0.18-3.5,0.68-5.1,1.5l-0.33,0.17V5z M48.26,16.67v4.15H36.67v-4.15c0-1.42,1.16-2.57,2.58-2.57h6.44
                                    C47.11,14.1,48.26,15.25,48.26,16.67z M27.39,16.67v4.15H15.8v-4.15c0-1.42,1.15-2.57,2.57-2.57h6.44
                                    C26.23,14.1,27.39,15.25,27.39,16.67z M61.43,35.17c0.01,0.08,0.02,0.15,0.02,0.22v11.92H2.55V35.4c0-0.07,0.01-0.14,0.02-0.21
                                    c0.01-0.05,0.01-0.08,0.01-0.12v-0.22h58.83v0.22C61.42,35.11,61.42,35.14,61.43,35.17z M61.07,32.52H2.93l0.08-0.28
                                    c1.42-5.35,6.2-9.09,11.62-9.09h34.73c5.43,0,10.21,3.74,11.63,9.09L61.07,32.52z"/>
                            </svg>
                            {props.features.bedrooms} {props.features.bedrooms>1 ? intl.formatMessage({id:"card-article-nbr-bed", defaultMessage: "Chambres"}): intl.formatMessage({id:"bedroom", defaultMessage: "Chambre"})}
                        </div>
                    </>
                    }
            </div>
                <div className="card-price">
                    {renderPrice}
                    <LocaleLink to={`/bien/${props.id}`}>
                        {intl.formatMessage({id: "card-articles-btn-see-more", defaultMessage: "Voir les détails"})}
                    </LocaleLink>
                </div>
            </div>
        </article>
    );
};

export const CardArticleSkeleton:FunctionComponent = ()=>{
    return(
        <div className="card">
            <Skeleton className="card-carousel"/>
            <div className="card-info">
                <Skeleton width="50%"/>
                <Skeleton count={2} className="skeleton-card-title"/>
                <Skeleton/>
                <div className="card-price">
                    <Skeleton width="8rem"/>
                    <Skeleton className="skeleton-card-btn"/>
                </div>
            </div>
        </div>    )
};

export default CardArticle;