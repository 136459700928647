import React, {FunctionComponent, ReactNode, useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import { useIntl } from "react-intl";
import {classNames} from "@ct-react/core";
import "./common.scss";
import { LocaleLink } from "@ct-react/locale";

type ButtonContactProps = { type?: "primary" | "white" };

type CustomLinkProps = {
  to:string,
  children:ReactNode
};

type ButtonTelNumberProps = {
  type?: "primary" | "white"
  mail?:string
};

type FormButtonProps = {
  value:string,
  disabled:boolean,
  onSubmit:()=>void
};

export const ButtonTelNumber = ({ type = "primary", mail = "a.reuse@auclubimmobilier.ch" }: ButtonTelNumberProps) => {

  const buttonClasses = classNames("btn", type);
  const fill = type === "primary" ? "var(--white)" : "var(--primary)";

  return (
    <button className={buttonClasses}>
      <a href={`mailto:${mail}`}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24.019" height="16.558" viewBox="0 0 24.019 16.558">
            <path d="M0,52.825c.29.176.586.342.869.529Q3.6,55.163,6.327,56.977c.575.381,1.156.753,1.752,1.141l-1.516.961Q5.2,59.941,3.841,60.8,2.019,61.961.2,63.118a.7.7,0,0,1-.2.054V52.825" transform="translate(0 -49.721)" fill={fill}/>
            <path d="M12.848,0q5.185,0,10.37,0a1.758,1.758,0,0,1,1.07.321,1.583,1.583,0,0,1,.524.784c.043.116,0,.192-.119.272-1.446.948-2.883,1.91-4.325,2.864q-1.991,1.319-3.987,2.632c-1.126.741-2.256,1.474-3.38,2.218-.1.065-.147.082-.257.008Q11.091,7.99,9.426,6.9,6.446,4.929,3.463,2.966c-.8-.53-1.609-1.058-2.409-1.594C.993,1.331.921,1.22.936,1.164A1.4,1.4,0,0,1,2.139.05C2.423.023,2.709,0,2.994,0q4.927,0,9.854,0" transform="translate(-0.879 0)" fill={fill}/>
            <path d="M12.889,166.5q-5.3,0-10.606,0a1.441,1.441,0,0,1-1.329-1.179c-.012-.052.06-.15.118-.188.611-.394,1.227-.779,1.841-1.168q1.372-.869,2.744-1.738,1.872-1.182,3.745-2.36c.244-.154.484-.314.725-.473a.317.317,0,0,1,.412,0c.613.42,1.249.8,1.86,1.228a.917.917,0,0,0,.965.006c.618-.425,1.257-.819,1.877-1.242a.3.3,0,0,1,.376-.01c1.008.644,2.011,1.3,3.021,1.937q2.177,1.381,4.362,2.75c.563.355,1.123.715,1.693,1.059a.239.239,0,0,1,.128.328,1.533,1.533,0,0,1-1.444,1.061q-5.244-.01-10.488,0" transform="translate(-0.897 -149.945)" fill={fill}/>
            <path d="M278.743,51.794v10.5l-8.116-5.141,8.116-5.358" transform="translate(-254.723 -48.75)" fill={fill}/>
          </svg>
        </div>
        <div>
          <p>Email</p>
          <span>{mail}</span>
        </div>
      </a>
    </button>);

};

export const ButtonEmail = ({ type = "primary" }: ButtonContactProps) => {
  const intl = useIntl();
  const buttonClasses = classNames("btn", type);
  const fill = type === "primary" ? "var(--white)" : "var(--primary)";

  return (
    <button className={buttonClasses}>
      <a href="tel:0275650565">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="23.389" height="23.389" viewBox="0 0 23.389 23.389">
            <path id="phone-solid" d="M7.533,1.1A1.822,1.822,0,0,0,5.367.042l-4.02,1.1A1.833,1.833,0,0,0,0,2.9,20.466,20.466,0,0,0,20.465,23.367a1.833,1.833,0,0,0,1.763-1.348l1.1-4.02a1.822,1.822,0,0,0-1.06-2.165l-4.385-1.827a1.821,1.821,0,0,0-2.115.53l-1.845,2.252A15.439,15.439,0,0,1,6.578,9.448L8.83,7.607a1.823,1.823,0,0,0,.53-2.115L7.533,1.107Z" fill={fill} />
          </svg>
        </div>
        <div>
          <p>{intl.formatMessage({id: "btn-prendre-contact", defaultMessage: "Prendre contact"})}</p>
          <span>+41 27 565 05 65</span>
        </div>
      </a>
    </button>);

};

export const ButtonToTop = () => {

  const btnRef = useRef(null);
  const offsetGapRef = useRef<number>(200);
  const [ isVisible, setIsVisible ] = useState<boolean>(false);

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", () => onScroll());
    return () => { window.removeEventListener("scroll", () => onScroll()); };
  }, []);

  useEffect(() => {
    gsap.to(btnRef.current, {
      x: isVisible ? 0 : 100,
      autoAlpha: isVisible ? 1 : 0
    });
  }, [ isVisible ]);

  const onScroll = () => setIsVisible(window.scrollY > offsetGapRef.current);
  const handleOnClick = () => window.scrollTo(0,0);

  return (
    <div ref={btnRef}
         className="btnToTop"
         onClick={handleOnClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="var(--white)" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
      </svg>
    </div>);

};

export const FormButton:FunctionComponent<FormButtonProps> = ({value,disabled,onSubmit})=>{
  return(
      <div className="form-button">
          <button type="button" className="primary btn submit" disabled={disabled} onClick={()=>onSubmit()}>
            <span>
              {value}
            </span>
          </button>
      </div>
  )
};

export const CustomLink:FunctionComponent<CustomLinkProps> = ({to,children}) => {
  return(
    <LocaleLink className="custom-link" to={to}>
      {children}
    </LocaleLink>
  )
};

export const CustomButton:FunctionComponent<CustomLinkProps> = ({to,children}) => {
  return(
    <LocaleLink className="custom-button" to={to}>
      <span>{children}</span>
    </LocaleLink>
  )
};
