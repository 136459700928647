import React,{FunctionComponent, useEffect, useMemo, useReducer, useRef, useState} from "react";
import gsap from "gsap";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { ARTICLE_FOR_SALE } from "../../../shared/fragments";
import CardArticle, { CardArticleSkeleton } from "../../components/card";
import { classNames } from "@ct-react/core";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faChevronLeft,
    faEllipsis,
    faMap,
} from "@fortawesome/free-solid-svg-icons";
import { SearchAccomodationArticles } from "../../../types/searchArticles";
import articlesPaginationReducer from "../../reducer/articlesPagination.reducer";
import { MapAsyncPopupMarker, MapContainer, SearchModule } from "../../components/loadable/loadable.component";
import { Marker } from "src/types/articles";
import {Section} from "../../components/wrapper/section";

import "./articles.scss";


type ArticlesPaginationProps = {
    pageInfo:any,
};

const initialSearchArticleData = new SearchAccomodationArticles();

const Articles:FunctionComponent = () => {
    const intl = useIntl();
    const [searchParams,setSearchParams] = useSearchParams();
    const [saleContent,setSaleContent] = useState<any|null>(null);
    const [displayable,isDisplayable] = useState<boolean>(false);
    const [articleSearchData,dispatchArticleSearchData] = useReducer<(articleSearchData: SearchAccomodationArticles, action: any) => any>(articlesPaginationReducer,initialSearchArticleData);
    const [searchModuleIsVisible, setSearchModuleIsVisible] = useState<boolean>(false);
    const [showMap,setShowMap] = useState<boolean>(false);

    //DATA :
    const { error, loading, refetch } = useQuery(ARTICLE_FOR_SALE, {
        variables:{
            first:articleSearchData.first,
            after:articleSearchData.after,
            last:articleSearchData.last,
            before:articleSearchData.before,
            rooms:articleSearchData.where.minRooms,
            surface:articleSearchData.where.minSurface,
            price:articleSearchData.where.price,
            objectTypes:articleSearchData.where.objectTypes,
            regions:articleSearchData.where.regions
        },
        ssr: true,
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted:(data)=>{
            setSaleContent(data.search)
            isDisplayable(true)
        }
    });

    useEffect(()=>{ //STEP 2: Dispatch search data changes as soon as URL changes
        dispatchArticleSearchData({
            direction:searchParams.get("dir"),
            id: searchParams.get("page"),
            minRooms:searchParams.get("minRooms"),
            price:searchParams.get("price"),
            minSurface:searchParams.get("minSurface"),
            objectTypes:searchParams.get("objectTypes"),
            regions:searchParams.get("regions")
        });
    },[searchParams]);

    useEffect(()=>{ //STEP 4: Refetch datas as soon as query change
        refetch();
    },[articleSearchData]);

    //MAP :
    const container = useRef(null);
    const mapRef = useRef(null);

    const handleShowMap = () => {
        setShowMap(!showMap)
    };

    useEffect(()=>{
        if(!!container){
            showMap&&gsap.to(
                container.current,
                {
                    gridTemplateColumns:"55% 45%",
                    duration:1,
                    ease:"power1"
                }
            );

            !showMap&&gsap.to(
                container.current,
                {
                    gridTemplateColumns:"100% 40%",
                    duration:1,
                    ease:"power1"
                }
            );
        }
    },[showMap]);

    //RENDERING RESULT :

    const renderArticles = useMemo(() => {
        if(loading){ // Loading
            let cards = [];
            for (let i = 0; i < 8; i++) {
                cards.push(<CardArticleSkeleton key={i}/>);
            }
            return cards;
        }
        else{ //Result
            return (
                <>
                    {!loading && displayable && !!saleContent.edges && saleContent.edges.map((article:any)=>{
                        return <CardArticle key={article.cursor} props={article.node} />
                    })}
                </>
            );
        }
    },[loading, saleContent]);

    const renderHeader = useMemo(() => {
        if(loading){ //Loading
            return <></>;
        }else if( displayable && saleContent.edges.length === 0 ){//No Articles
            return(
                <div className="articles-header empty">
                    <div className="articles-header-empty" style={{backgroundColor:"var(--light-grey)",display:"flex"}}>
                        <h4>
                            {intl.formatMessage({id:"articles-no-accomodations-correspond", defaultMessage: "Aucun biens ne correspond aux filtres choisis ."})}
                        </h4>
                        <p>
                            {intl.formatMessage({id:"articles-modify-your-filters", defaultMessage: "Veuillez réinitialiser les filtres ou les modifier."})}
                        </p>
                    </div>
                </div>
            );
        }else if(error){ //Error
            return(
                <div className="articles-header empty">
                    <div className="articles-header-empty" style={{backgroundColor:"var(--light-grey)",display:"flex"}}>
                        <h4>
                            {intl.formatMessage({id:"articles-no-accomodations", defaultMessage: "Aucun biens disponible pour le moment ."})}
                        </h4>
                        <p>
                            {intl.formatMessage({id:"articles-no-accomodations-error", defaultMessage: "Nous n'avons pas de bien immobilier à vous présenter dans cette catégorie."})}
                        </p>
                    </div>
                </div>
            );
        }else{ //Result
            return(
                <div className="articles-header">
                    <h2>
                        {intl.formatMessage({id: "title-bien-en-vente", defaultMessage: "Nos biens {break} à la vente"},{break: <br/>})}
                    </h2>
                    <div className="toggle-map">
                        {intl.formatMessage({id:"articles-map", defaultMessage: "Carte"})}
                        <label className="switch">
                            <input className="toggle-btn" type="checkbox" onChange={handleShowMap} checked={showMap}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            );
        }
    },[loading, saleContent,showMap]);

    return(
        <>
        <SearchModule isVisible={searchModuleIsVisible} setIsVisible={setSearchModuleIsVisible}/>
        <Section id="articles">
            {renderHeader}
            <div ref={container} className="articles-container" style={ error ? { display: "none" } : undefined }>
                <div className="articles">
                    {renderArticles}
                </div>
                <div className={classNames("articles-map",{showMap:showMap})}>
                    <div className="articles-map-button" onClick={handleShowMap}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span>
                                {intl.formatMessage({id:"btn-list", defaultMessage: "Liste"})}
                            </span>
                    </div>
                    {(displayable && saleContent.allMarkers) &&
                    <MapContainer
                        className="overloaded-map"
                        center={[46.02835544029722, 7.116572852875435]}
                        zoom={10}
                        clusterize={true}
                        ref={mapRef}
                        >
                        {saleContent.allMarkers.map((marker:Marker)=><MapAsyncPopupMarker marker={marker} key={marker.id} mapRef={mapRef}/>)}
                    </MapContainer>}
                </div>
                <div className="articles-btnMap" >
                    <div onClick={handleShowMap}>
                        <FontAwesomeIcon icon={faMap}/>
                        {intl.formatMessage({id:"articles-see-map", defaultMessage: "Voir la carte"})}
                    </div>
                    <div onClick={()=>setSearchModuleIsVisible(true)}>
                        <svg version="1.1" width="1.3rem" height="1.3rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.86 14.18">
                            <g transform="translate(-698.812 -756.268)" fill="var(--white)">
                                <path d="M705.42,756.56c1.56,0,3.11,0,4.67,0c0.5-0.03,0.98,0.19,1.27,0.59
                                    c0.41,0.57,0.34,1.35-0.16,1.84c-0.79,0.89-1.6,1.78-2.4,2.66c-0.33,0.37-0.67,0.73-0.99,1.11c-0.09,0.11-0.14,0.25-0.15,0.39
                                    c-0.01,1.89,0,3.79-0.02,5.69c0,0.28-0.08,0.56-0.22,0.8c-0.28,0.49-0.91,0.66-1.4,0.38c-0.05-0.03-0.1-0.06-0.14-0.1
                                    c-0.79-0.56-1.55-1.15-2.32-1.74c-0.29-0.24-0.45-0.6-0.41-0.98c0.01-1.34,0-2.67,0.01-4.01c0-0.18-0.06-0.35-0.19-0.48
                                    c-1.12-1.23-2.23-2.46-3.34-3.69c-0.27-0.27-0.43-0.63-0.43-1.01c-0.01-0.79,0.63-1.44,1.42-1.45c0.03,0,0.07,0,0.1,0
                                    C702.29,756.56,703.85,756.56,705.42,756.56z"/>
                                <path d="M713.89,764.47c-0.72,0-1.45,0-2.17,0c-0.46,0.04-0.9-0.21-1.09-0.63
                                    c-0.28-0.55-0.05-1.23,0.5-1.51c0.18-0.09,0.38-0.13,0.58-0.12c1.46,0,2.93,0,4.39,0c0.09,0,0.19,0.01,0.28,0.03
                                    c0.6,0.12,1,0.7,0.88,1.3c-0.09,0.44-0.43,0.79-0.88,0.88c-0.17,0.02-0.34,0.04-0.52,0.03
                                    C715.21,764.48,714.55,764.47,713.89,764.47z"/>
                                <path d="M713.89,770.14c-0.72,0-1.45,0-2.17,0c-0.46,0.05-0.9-0.21-1.09-0.63
                                    c-0.28-0.55-0.06-1.23,0.5-1.51c0.19-0.09,0.4-0.14,0.61-0.12c1.45,0.01,2.89,0,4.34,0c0.1,0,0.2,0.01,0.3,0.03
                                    c0.6,0.11,1,0.69,0.89,1.3c-0.08,0.45-0.44,0.81-0.9,0.89c-0.12,0.02-0.23,0.03-0.35,0.03
                                    C715.31,770.14,714.6,770.14,713.89,770.14z"/>
                                <path d="M715.04,758.78c-0.46,0-0.93,0.03-1.39-0.01c-0.6-0.14-0.97-0.73-0.83-1.33
                                    c0.09-0.41,0.42-0.74,0.83-0.83c0.77-0.05,1.55-0.04,2.33-0.05c0.14,0,0.28,0.01,0.42,0.04c0.53,0.09,0.9,0.56,0.87,1.09
                                    c0.04,0.54-0.35,1.01-0.88,1.08C715.94,758.8,715.49,758.8,715.04,758.78L715.04,758.78z"/>
                            </g>
                        </svg>
                        {intl.formatMessage({id:"articles-sort-filters", defaultMessage: "Trier et filtrer"})}
                    </div>
                </div>
            </div>
            {displayable && <ArticlesPagination pageInfo={saleContent.pageInfo}/>}
        </Section>
        </>
    );
};

const ArticlesPagination:FunctionComponent<ArticlesPaginationProps> = ({ pageInfo }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth"});

    const pagination:React.ReactNode[] = [];

    if(!!pageInfo.linkCursors){
        for (const page of pageInfo.linkCursors) {
            pagination.push(
                <div key={page.page} onClick={()=>changePage("after" , page.cursor)} className={classNames("pagination",{currentPage:page.page=== pageInfo.page})}>
                    {page.page}
                </div>
                )
        }
    }

    const changePage = (dir:string | null ,cursor:string | null)=>{
        if(dir === null) {setSearchParams()} //page 1
        if(dir === "before") {setSearchParams(`dir=before&page=${cursor}`)} //previous page
        if(dir === "after") {setSearchParams(`dir=after&page=${cursor}`)} //next page

    }

    return(
        <div className="articles-pagination">
        <div className="pagination-container">
            {
            pageInfo.pageCount > 1 &&
                <button disabled={!pageInfo.hasPreviousPage} className="pagination pagination-before" onClick={()=>changePage("before", pageInfo.previousCursor)}>
                    <FontAwesomeIcon icon={faAngleLeft}/>
                </button>
            }

            <div onClick={()=>changePage(null,null)} className={classNames("pagination",{currentPage: pageInfo.page === 1})}>
            1
            </div>

            {pageInfo.page > 3 && <FontAwesomeIcon icon={faEllipsis}/>}

            {pagination}

            {(pageInfo.page <= pageInfo.pageCount - 3 && pageInfo.pageCount > 4 ) && <FontAwesomeIcon icon={faEllipsis}/>}

            {
            pageInfo.pageCount > 1 &&
                <div onClick={()=>changePage("after", pageInfo.lastCursor)} className={classNames("pagination",{currentPage: pageInfo.page === pageInfo.pageCount})}>
                    {pageInfo.pageCount}
                </div>
            }

            {
            pageInfo.pageCount > 1 &&
                <button disabled={!pageInfo.hasNextPage} className="pagination pagination-after" onClick={()=>changePage("after", pageInfo.nextCursor)}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </button>
            }

        </div>
    </div>
    )
};

export default Articles;
