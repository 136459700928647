
export default class defaultFormData{
    name:string;
    nameValid:boolean;
    surname:string;
    surnameValid:boolean;
    email:string;
    emailValid:boolean;
    tel:string;
    telValid:boolean;
    message:string;
    messageValid:boolean;

    constructor(
        name:string = "",
        nameValid:boolean=false,
        surname:string = "",
        surnameValid:boolean=false,
        email:string = "",
        emailValid:boolean=false,
        tel:string = "",
        telvalid:boolean=false,
        message:string = "",
        messageValid:boolean=false,
    ){
        this.name = name;
        this.nameValid = nameValid;
        this.surname = surname;
        this.surnameValid = surnameValid;
        this.email = email;
        this.emailValid = emailValid;
        this.tel = tel;
        this.telValid = telvalid;
        this.message = message;
        this.messageValid = messageValid;
    }
};