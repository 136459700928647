import { SearchData } from "../../types/searchData";

type Action={
    type:string;
    value:any;
};

const initialValue = new SearchData();

export default function searchDataReducer(searchData:SearchData, action:Action) {    
    switch (action.type) {
        case ("minPrice"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price: !!searchData.price ? [action.value,searchData.price[1]] : [ 0, 5000000],
                }
            )
        }
        case ("maxPrice"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price: !!searchData.price ? [searchData.price[0],action.value] : [ 0, 5000000],
                }
            )
        }
        case ("addRegions"):{
            return(
                {
                    ...searchData,
                    regions:action.value,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("addTypeOfArticle"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:action.value,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("removeTypeOfArticle"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:action.value,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("addRoom"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    minRooms: action.value,
                }
            )
        }
        case ("surface"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    minSurface: action.value,
                }
            )
        }
        case ("priceReset"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price: [0,5000000]
                }
            )
        }
        case ("resetAll"):{
            return(
                {
                    ...initialValue,
                    typeOfArticle:initialValue.typeOfArticle,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("RegionsReset"):{
            return(
                {
                    ...searchData,
                    regions:initialValue.regions,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("typeOfArticleReset"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:initialValue.typeOfArticle,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("roomReset"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    minRooms: initialValue.minRooms,
                    price:!!searchData.price ? [...searchData.price] : null
                }
            )
        }
        case ("surfaceReset"):{
            return(
                {
                    ...searchData,
                    typeOfArticle:[...searchData.typeOfArticle],
                    price:!!searchData.price ? [...searchData.price] : null,
                    minSurface: initialValue.minSurface,
                }
            )
        }
        default:{
            throw Error('Unknown action: ' + action.type);
        }
    };
};


