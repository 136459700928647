import React,{FunctionComponent} from "react";
import { MapContainer, MapMarker } from "../loadable/loadable.component";

import "./maps.scss";

type ArticleMapProps =  {
    coordinates:number[]
};

export const ArticleMarker = ()=>{
    return(
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="3.5rem" height="3.5rem" viewBox="0 0 63.2 63.2">
            <path fill="var(--primary)" d="M47.1,10.9c-8.9-8.6-23-8.3-31.6,0.5c-8.6,8.9-8.3,23,0.5,31.6c4.4,4.3,16.1,15.5,16.1,15.5l15.5-16.1
            C56.3,33.7,56,19.5,47.1,10.9z M31.7,38.2c-5.7,0-10.3-4.9-10.3-11c0-6.1,4.6-11,10.3-11s10.3,4.9,10.3,11
            C41.9,33.3,37.3,38.2,31.7,38.2z"/>
        </svg>
    )
};

export const ArticleMap:FunctionComponent<ArticleMapProps> = ({coordinates})=>{

    return(
        <div className="article-map">
            <MapContainer center={coordinates} zoom={17}>
                <MapMarker position={coordinates} icon={<ArticleMarker/>} />
            </MapContainer>
        </div>
    )
};
