import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {AppConfig, AppConfigProvider} from "@ct-react/core";
import {defaultLocale, useLocaleContext} from "@ct-react/locale";
import {IntlProvider} from 'react-intl';
import {getIntlMessages} from "./i18n/index";
import Menu from "./layout/menu/menu";
import Footer from "./layout/footer/footer";
import { ButtonToTop } from "./components/common";
import LocaleRoutes from "./routes";
import { SearchDataProvider } from "./contexts/searchDataContext";

import "../styles/index.scss"
import { SearchModule } from "./components/loadable/loadable.component";
import { useLocation } from "react-router";

const appConfig = {
  id: process.env.APP_ID!,
  env: process.env.NODE_ENV!,
  baseUrl: process.env.BASE_URL!
} as AppConfig;

const App = () => {
  const location = useLocation();
  const { locale } = useLocaleContext();

  useEffect(()=>{window.scrollTo(0,0)},[location])

  return(
    <AppConfigProvider value={appConfig}>
      <IntlProvider locale={locale.code}
                    defaultLocale={defaultLocale.code}
                    messages={getIntlMessages(locale)}
                    onError={() => void 0}
      >
        <Helmet>
          <html lang={locale.code} />
          <title>Au Club Immobilier</title>
          <link rel="canonical" href={appConfig.baseUrl} />
          <meta property="og:locale" content={locale.code} />
          <meta property="og:title" content="Au Club Immobilier" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="auclubimmobilier.ch" />
          <meta name="keywords" content="Au club immobilier, Agence, Immobilier, Agence immobilière, Champex-Lac, Entremont, Valais, Suisse, Vente, Vente immobilière, Acquisition, Acquisition immobilière, Location, Location immobilière, Gestion administrative" />
        </Helmet>
        <Menu />
        <main>
          <SearchDataProvider state={false} >
            <LocaleRoutes/>
          </SearchDataProvider>
        </main>
        <ButtonToTop />
        <Footer />
      </IntlProvider>
    </AppConfigProvider>
    );

}

export default App
