import { useQuery } from "@apollo/client";
import React,{
    FunctionComponent,
    useEffect,
    useMemo,
    useState
} from "react";
import { useLocation } from "react-router";
import {
    ARTICLE_DESCRIPTION,
    ARTICLE_SUMMARY,
} from "../../../shared/fragments";
import parse from 'html-react-parser';
import { ArticleMarker } from "../../components/maps/maps";
import { useIntl } from "react-intl";
import { LocaleLink, useLocaleContext, useNavigate } from "@ct-react/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faChevronLeft, faDownload, faHeart } from "@fortawesome/free-solid-svg-icons";
import { ArticleImages, SaleArticleSummary } from "../../../types/articles";
import { ArticleCarouselSuggestions, ArticleInfoCarousel } from "../../components/carousel";
import ContactForm from "../../components/forms/contactForm";
import { formatPrice } from "../../../shared/utils";
import { Section } from "../../components/wrapper/section";
import { OneColumn, TwoColumn } from "../../components/wrapper/column";
import Skeleton from "react-loading-skeleton";
import { MapContainer, MapMarker } from "../../components/loadable/loadable.component";
import { CustomButton } from "../../components/common";

import "./articleInfo.scss";

type ImagesProps = {
    props:ArticleImages[],
};

type ArticleEquipementProps = {
    props: SaleArticleSummary,
};

const ArticleInfo:FunctionComponent=()=>{
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const [articleDescription,setArticleDescription] = useState<string|null>(null);
    const [articleSummary,setArticleSummary] = useState<any|null>(null);
    const [displayable,setDisplayable] = useState<boolean>(false);

    const articleId = location.pathname.split("/")[3];

    const {loading: descriptionLoading} = useQuery(ARTICLE_DESCRIPTION,{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setArticleDescription(data.articleDescription?.value || null );
        },
        onError:(error)=>{
            navigate("notFound");
        }
    });

    const {loading: summaryLoading} = useQuery(ARTICLE_SUMMARY,{
        variables:{
            articleId:articleId
        },
        onCompleted:(data)=>{
            setArticleSummary(data.articleSummary);
            setDisplayable(true);
        },
        onError:(error)=>{
            navigate("notFound");
        }
    });

    const renderTitle = useMemo(()=>{
        if(summaryLoading) return <Skeleton/>;
        if(!summaryLoading && articleSummary!){
            return(
                <>
                    {articleSummary.title.value } | {!!articleSummary.features.rooms && articleSummary.features.rooms + " "}
                    {intl.formatMessage({id:"rooms",  defaultMessage: " Pièces"})}
                    {!!articleSummary.features.livingSpace && 
                        <>
                        | 
                        {" " + articleSummary.features.livingSpace}
                        m² | {articleSummary.location.city}
                        </>
                    }
                </>
            );

        }
    },[articleSummary, summaryLoading])

    const renderPrice = useMemo(()=>{
        if(summaryLoading) return <Skeleton/>;
        if(displayable && articleSummary.priceOnDemand) return(<span >{intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"})}</span>);
        if(displayable && !!articleSummary.price.amount) return(<span >{articleSummary.price.currency} {formatPrice(articleSummary.price.amount)}.-</span>);
        return (<span>{intl.formatMessage({ id:"price-on-request-only", defaultMessage: "Prix sur demande"})}</span>)
    },[articleSummary, summaryLoading]);

    return(
        <>
            <section className="article-section">
                <header className="article-header">
                    <div onClick={()=>navigate(-1)}>
                        <FontAwesomeIcon className="article-header-chevronLeft" icon={faChevronLeft} color="var(--primary)"/>
                        <span className="article-header-navigator">
                            {intl.formatMessage({id:"btn-back",  defaultMessage: "Retour"})}
                        </span>
                    </div>
                    <h1 className="article-header-h1">
                        {renderTitle}
                    </h1>
                    <div className="article-header-info">
                        <div className="article-header-price">
                            {renderPrice}
                        </div>
                        <div className="article-header-icones">
                            {/* <div className="article-header-icone" data-gloss="Ajouter aux favoris">
                                <FontAwesomeIcon icon={faHeart} size="1x" />
                            </div> */}
                            <div className="article-header-icone" data-gloss="Imprimer en PDF">
                                <FontAwesomeIcon icon={faDownload} size="1x" onClick={()=>window.print()}/>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="article-image">
                    {
                        !summaryLoading && displayable && articleSummary.features.isSold && <div className="chip-sale">{intl.formatMessage({id:"sold", defaultMessage : "Vendu"})}</div>
                    }
                    {summaryLoading && <ArticleImageGridSkeleton/>}
                    {!summaryLoading && displayable && <ArticleImageGrid props={articleSummary.images}/>}
                </div>

                <Section id={"articleInfo"}>
                    <TwoColumn>
                        <div className="left-box">
                            <h1 className="article-info-h1">
                            {renderTitle}
                            </h1>
                            <div className="article-info-price">
                                {renderPrice}
                            </div>
                            <div className="article-description">
                                <h2>
                                    {intl.formatMessage({ id:"description", defaultMessage: "Description {break} du bien"},{ break: <br/> })}
                                </h2>
                                <div className="article-description-content">
                                    {descriptionLoading && <Skeleton/>}
                                    {!descriptionLoading && !!articleDescription ? parse(articleDescription) : intl.formatMessage({ id:"no-description", defaultMessage: "Ce bien ne possède pas de description pour le moment. Pour plus d'informations veuillez nous contacter."}) }
                                </div>

                                {summaryLoading && <ArticleEquipementSkeleton/>}
                                {!summaryLoading && displayable && <ArticleEquipement props={articleSummary}/>}
                            </div>
                        </div>
                        <div className="right-box">
                            <article className="article-form">
                                <h2>
                                    {intl.formatMessage({ id:"articleInfo-interest-in-the-accomodation", defaultMessage: "Formulaire de contact"})}
                                </h2>
                            <ContactForm/>
                            {/* <ShareIcones props={props}/> */}
                            </article>
                        </div>
                    </TwoColumn>
                </Section>

            </section>
            <Section id={"article-location"}>
            {summaryLoading && <Skeleton className="article-map"/>}
            {displayable&& !summaryLoading &&
                <div className="article-map">
                    <MapContainer center={articleSummary.coordinates} zoom={17}>
                        <MapMarker position={articleSummary.coordinates} icon={<ArticleMarker/>} />
                    </MapContainer>
                </div>
            }
            </Section>
            <Section id="article-carousel">
                <OneColumn title={intl.formatMessage({id: "", defaultMessage: "Autres logements {break} À vendre"},{break: <br/>})}>
                    <ArticleCarouselSuggestions/>
                    <CustomButton to="nos-biens">
                        {intl.formatMessage({id: "btn-see-all-article", defaultMessage: "Voir tous les biens"})}
                    </CustomButton>
                </OneColumn>
            </Section>
        </>
    );
};

const ArticleImageGrid:FunctionComponent<ImagesProps> = ({props})=>{
    const intl = useIntl();
    const [showCarousel,setShowCarousel] = useState<boolean>(false);
    const images = [...props];

    const handleShowCarousel = ()=>{
        setShowCarousel(!showCarousel)
    };

    useEffect(()=>{
        if(showCarousel) document.body.style.overflow = "hidden";
        if(!showCarousel) document.body.style.overflow = "initial";
    },[showCarousel])

    if(images.length<=0){
        return(
            <div className="article-image-empty">
                <FontAwesomeIcon icon={faCamera}/>
                <p>
                    {intl.formatMessage({id:"no-image", defaultMessage: "Aucune image disponible"})}
                </p>
            </div>
        )
    }
    return(
        <>
            <ArticleInfoCarousel
                isActive={showCarousel}
                setIsActive={setShowCarousel}
                props={images}
                onClick={()=>window.innerWidth < 960 && handleShowCarousel()}
                />
            <div className="article-image-grid">
                <div style={{backgroundImage:`url(${images[0].original})`}}></div>
                <div style={{backgroundImage:`url(${images[1].original})`}}></div>
                <div style={{backgroundImage:`url(${images[2].original})`}}></div>
                <div style={{backgroundImage:`url(${images[3].original})`}}></div>
                <div style={{backgroundImage:`url(${images[4].original})`}}></div>
                <div className="grid-btn" onClick={handleShowCarousel}>
                    <FontAwesomeIcon icon={faCamera}/>
                    {intl.formatMessage({ id:"carousel-see-photos", defaultMessage: "Voir les {length} photos"},{length:images.length})}
                </div>
            </div>
        </>
    );
};

const ArticleImageGridSkeleton = () => {
    return(
        <div className="article-image-grid">
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
            <Skeleton></Skeleton>
        </div>
    );
}

const ArticleEquipement:FunctionComponent<ArticleEquipementProps> = ({props}) => {
    const intl = useIntl();
    const {locale} = useLocaleContext();

    const translateOrientation = (orientation: string)=>{
        if(locale.basename === 'en'){
            orientation = orientation.toLocaleLowerCase();
            return orientation[0].toUpperCase() + orientation.slice(1);
        }else{
            switch (orientation) {
                case "SOUTH":
                    return "Sud";
                    break;
                case "NORTH":
                    return "Nord";
                    break;
                case "WEST":
                    return "Ouest";
                    break;
                case "EAST":
                    return "Est";
                    break;
                case "PANORAMIC":
                    return "Panoramique";
                    break;
                default:
                    "-"
                    break;
            }

        }
    };

    return(
        <div className="article-equipment">
            <ul>
                <li><b>{intl.formatMessage({ id:"price", defaultMessage: "Prix"})}          </b><b><span>:</span></b> <p>{props.priceOnDemand? intl.formatMessage({ id:"price-on-demand", defaultMessage: "Prix sur demande"}) : props.price.amount}</p></li>
                <li><b>{ props.features.bedrooms === null || props.features.bedrooms<=1 ? intl.formatMessage({ id:"bedroom", defaultMessage: "Chambre"}) : intl.formatMessage({ id:"card-article-nbr-bed", defaultMessage: "Chambres"})}       </b><b><span>:</span></b> <p>{!!props.features.bedrooms? props.features.bedrooms : "-"}</p></li>
                <li><b>{ props.features.bathrooms === null || props.features.bathrooms<=1 ? intl.formatMessage({ id:"bathroom", defaultMessage: "Salle de bain"}): intl.formatMessage({id:"card-article-nbr-bathrooms", defaultMessage:"Salles de bains"})} </b><b><span>:</span></b> <p>{!!props.features.bathrooms? props.features.bathrooms : "-"}</p></li>
                <li><b>{intl.formatMessage({ id:"parking", defaultMessage: "Parking"})}       </b><b><span>:</span></b> <p>{props.features.withParking? intl.formatMessage({id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({id:"no", defaultMessage: "Non"})}</p></li>
                <li><b>{intl.formatMessage({ id:"furniture", defaultMessage: "Meublé"})}        </b><b><span>:</span></b> <p>{!!props.features.isFurnished? intl.formatMessage({id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({id:"no", defaultMessage: "Non"})}</p></li>
                <li><b>{intl.formatMessage({ id:"charges", defaultMessage: "Charges"})}       </b><b><span>:</span></b> <p>{!!props.features.charges? `${props.features.charges.amount}${props.features.charges.currency}` : "-"}</p></li>
            </ul>
            <ul>
                <li><b>{intl.formatMessage({ id:"surface", defaultMessage: "Surface"})}             </b><b><span>:</span></b> <p>{!!props.features.livingSpace? `${props.features.livingSpace} m²` : "-" }</p></li>
                <li><b>{intl.formatMessage({ id:"orientation", defaultMessage: "Orientation"})}         </b><b><span>:</span></b> <p>{!!props.features.orientation? translateOrientation(props.features.orientation) : "-"}</p></li>
                <li><b>{intl.formatMessage({ id:"wc", defaultMessage: "WC"})}                  </b><b><span>:</span></b> <p>{!!props.features.restrooms?props.features.restrooms:"-"}</p></li>
                <li><b>{intl.formatMessage({ id:"heater", defaultMessage: "Chauffage"})}           </b><b><span>:</span></b> <p>{ props.features.withFireplace? intl.formatMessage({id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({id:"yes", defaultMessage: "Oui"})}</p></li>
                <li><b>{intl.formatMessage({ id:"sale-stranger", defaultMessage: "Vente aux étrangers"})}      </b><b><span>:</span></b> <p>{props.features.isSellableToForeigners? intl.formatMessage({id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({id:"no", defaultMessage: "Non"})}</p></li>
                <li><b>{intl.formatMessage({ id:"renovation-funds", defaultMessage: "Fonds de rénovation"})} </b><b><span>:</span></b> <p>{!!props.features.renovationFund? intl.formatMessage({id:"yes", defaultMessage: "Oui"}) : intl.formatMessage({id:"no", defaultMessage: "Non"})}</p></li>
            </ul>
        </div>
    );
};

const ArticleEquipementSkeleton = () => {
    return(
        <div className="article-equipment">
            <ul>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
            </ul>
            <ul>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
                <li><Skeleton/></li>
            </ul>
        </div>
    );
};

export default ArticleInfo;
