export class SearchData{
    regions:string
    typeOfArticle:string[];
    price:number[] | null;
    minRooms:string|null;
    minSurface:number;
    
    constructor(
        regions:string = "",
        typeOfArticle:string[] = [],
        price:null,
        minRooms:string|null = null,
        minSurface:number = 0,

    ){
        this.regions = regions;
        this.typeOfArticle = typeOfArticle;
        this.price = null;
        this.minRooms = minRooms;
        this.minSurface = minSurface
    }
}
