import React,{FunctionComponent, ReactNode} from "react";
import {classNames} from "@ct-react/core";
import "./column.scss";


type TitleBoxProps = {
    title:string | ReactNode;
};

type OneColumnProps = {
    title:string | ReactNode;
    children:ReactNode;
};

type TwoColumnProps = {
    revert?:boolean;
    children:ReactNode;
};

type ThreeColumnProps = {
    title:string | ReactNode;
    children:ReactNode;
};

type ImageBoxProps = {
    title?:string | ReactNode,
    image1:string,
    image2:string,
};

type TextboxProps = {
    title?:string | ReactNode,
    children:ReactNode,
};

export const TitleBox:FunctionComponent<TitleBoxProps> = ({title})=>{
    return(
        <div className="titleBox">
            <h2>{title}</h2>
        </div>
    )
};

export const OneColumn:FunctionComponent<OneColumnProps> = ({children,title})=>{

    return(
        <>
            <TitleBox title={title}></TitleBox>
            <div className="oneColumn">
                {children}
            </div>
        </>
    )
};

export const TwoColumn:FunctionComponent<TwoColumnProps> = ({revert=false,children})=>{

    return(
        <div className={classNames("twoColumn",{revertColumn:revert})}>
            {children}
        </div>
    );
};

export const ThreeColumn:FunctionComponent<ThreeColumnProps> = ({title,children})=>{

    return(
        <>
            <TitleBox title={title}></TitleBox>
            <div className="threeColumn">
                {children}
            </div>
        </>
    );
};

export const ImageBox:FunctionComponent<ImageBoxProps>= ({image1,image2,title=null})=>{
    const image1_section = `url(${image1})`
    const image2_section = `url(${image2})`

    return(
        <div className="image-box">
            {!!title && <TitleBox title={title}/>}
            <div className="images">
                <div className="image image1" style={{backgroundImage:image1_section}}></div>
                <div className="image image2" style={{backgroundImage:image2_section}}></div>
            </div>
        </div>
    );
};

export const Textbox:FunctionComponent<TextboxProps>=({children,title=null})=>{
    return(
        <div className="text-box">
            {!!title && <TitleBox title={title}/>}
            {children}
        </div>
    );
};

