import { gql } from "@apollo/client";

//COMMUN
export const IMAGE_FIELDS = gql`
  fragment ImageFields on Image {
    isMain
    medium
    large
    extraLarge
    original
  }
`;

export const LOCATION_FIELDS = gql`
  fragment LocationFields on Location {
    street,
    streetComplement,
    zip,
    city,
    state,
    country,
    resume
  }
`;

const PRICE_FIELDS = gql`
  fragment PriceField on Price {
    currency
    amount
  }
`;

//SEARCH DATA

export const OBJECT_QUERY = gql`
 query objectTypes($type: AccommodationArticleType)
 {
    searchableAccommodationObjectTypes(type: $type)
 }
`;

export const SALE_ALL_REGIONS = gql `
  query getAllRegions($type: AccommodationArticleType)
    {
      searchableAccommodationRegions(type: $type) {
        id
        slug
        name
      }
    }
`;

//SALE

export const SALE_FEATURES_FIELDS = gql`
  fragment SaleFeaturesFields on SaleAccommodationFeatures {
    rooms
    bedrooms
    livingSpace
    parkings
    bathrooms
    isSold
  }
`;

export const SALE_FEATURES_ALL_FIELDS = gql`
  ${PRICE_FIELDS}
  fragment SaleFeaturesAllFields on SaleAccommodationFeatures {
    __typename
    totalSpace
    livingSpace
    rooms
    bedrooms
    bathrooms
    restrooms
    parkings
    orientation
    withDishwasher
    withWashingMachine
    withFireplace
    withPool
    withSauna
    withLift
    withParking
    withGarage
    withBalcony
    withTerrace
    withOutdoorSpace
    isSold
    isNew
    isFurnished
    isSellableToForeigners
    charges {
      ...PriceField
    }
    workingCapitalFund {
      ...PriceField
    }
    renovationFund {
      ...PriceField
    }
  }
`;

export const ARTICLE_FOR_SALE = gql `
    ${IMAGE_FIELDS}
    ${SALE_FEATURES_FIELDS}
    query GetSalesSummaries(
        $first:Int, 
        $after:String, 
        $last:Int, 
        $before:String, 
        $rooms:Decimal, 
        $surface:Int, 
        $price:PriceRangeFilterInput, 
        $objectTypes:[String!],
        $regions:[ID!]
        )
        {
            search(
                where:{
                    saleAccommodation:{
                        rooms:$rooms,
                        price:$price,
                        surface:$surface, 
                        objectTypes:$objectTypes
                        regions:$regions
                    }
                }, 
                first:$first, 
                after:$after, 
                last:$last, 
                before:$before
                paginationLinksLength : 3
            )
            {
                totalCount
                edges {
                    cursor
                    node {
                        ... on SaleAccommodationSummary {
                            __typename,
                            id, 
                            title {value},
                            coordinates,
                            priceOnDemand,
                            objectType,
                            price {currency , amount},
                            images { ...ImageFields },
                            features { ...SaleFeaturesFields }
                            location {city}
                        }
                    }
                }
                pageInfo{
                    page
                    pageSize
                    pageCount
                    hasPreviousPage
                    previousCursor
                    hasNextPage
                    nextCursor
                    lastCursor
                    linkCursors { page cursor }
                }
                allMarkers { id coordinates }
            }
        }
`;

export const ARTICLE_DESCRIPTION = gql`
    query GetArticleDescription($articleId: ID!){
        articleDescription(articleId: $articleId){
            value
            matchRequestedLang
        }
    }
`;

export const ARTICLE_SUMMARY = gql`
    ${IMAGE_FIELDS}
    ${LOCATION_FIELDS}
    ${SALE_FEATURES_ALL_FIELDS}
    query GetArticleSummary($articleId: ID!){
        articleSummary(articleId: $articleId){
            ...on SaleAccommodationSummary{
                __typename,
                id,
                title {value},
                images{...ImageFields},
                location{...LocationFields},
                coordinates,
                priceOnDemand,
                objectType,
                price {currency , amount},
                withPlans,
                features{...SaleFeaturesAllFields}
            }
        }
    }
` ;

//EMAIL

export const EMAIL_MUTATION = gql`
    mutation sendEmail($firstName:String!, $lastName:String!, $email:String!, $phone:String!, $subject:String, $body:String!){
        mailAgency(agencyId: "9f7a881b-f97c-4575-81f3-cf345a54467d" , content:{firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, subject: $subject, body: $body})
    }
`;