export const generateSearchArticleParamData = (data:any)=>{
    let params:any[]=[];
    !!data.price && params.push(["price",data.price.join("-")]);
    !!data.regions && params.push(["regions",data.regions])
    !!data.minRooms && params.push(["minRooms",data.minRooms]);
    data.minSurface>0&&params.push(["minSurface",data.minSurface]);
    data.typeOfArticle.length>0&&params.push(["objectTypes",data.typeOfArticle.join("-")]);
    return params;
}

export const generateSearchArticleURL = (data:any)=>{
    let URL = data.map((a:any)=>a.join("=")).join("&");
    return URL;
}

export const formatPrice = (num:number)=>{
    if(num>=1000000) return num.toString().slice(0,1) + "'" + num.toString().slice(1).slice(0,3) + "'" + num.toString().slice(4);
    if(num>=100000) return num.toString().slice(0,3) + "'" + num.toString().slice(3);
    if(num>=10000) return num.toString().slice(0,2) + "'" + num.toString().slice(2);
    if(num>=1000) return num.toString().slice(0,1) + "'" + num.toString().slice(1);
    if(num<1000) return num;
}

export const formatPriceInK = (num:number)=>{
    if(num<1000) return "0k";
    if(num>=1000) return num / 1000 +"k";
}
