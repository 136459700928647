import React,{ FunctionComponent, RefObject, useEffect, useReducer, useRef, useState} from "react";
import gsap from "gsap";
import { useIntl } from "react-intl";
import { Header, Section } from "../../components/wrapper/section";
import { ImageBox, Textbox, TitleBox, TwoColumn } from "../../components/wrapper/column";
import ContactForm from "../../components/forms/contactForm";

import headerImg from "../../assets/contact-au-club-immobilier-champex-lac.jpg";
import image1 from "../../assets/champex-lac-copie.jpg";
import image2 from "../../assets/ski-champex.jpg";

import "./contact.scss";

const Contact:FunctionComponent = ()=>{
    const intl = useIntl();
    const [succeeded, setsucceeded] = useState<boolean | null>(null);
    const [section_1_Y, setSection_1_Y] = useState<number>(0);

    const section_1_image = useRef<HTMLDivElement|null>(null);

    //Init intersectObserver
    useEffect(() => {
        if(!!section_1_image){
            intersect_section_1(section_1_image);
        }
    }, []);

    //intersect observer section 1
    const intersect_section_1 = (elementRef: RefObject<Element>)=>{
        if(elementRef!=null){
            const node = elementRef?.current! // DOM Ref
            const observer = new IntersectionObserver(
                ([entry]:any) => {
                    setSection_1_Y(entry.intersectionRect.x)
                },
                { rootMargin: window.innerWidth>900?"-50%":"-40%" }
                );
                elementRef&&observer.observe(node)
                return () => observer.disconnect()
        }
    };

    //Animate section 1 images
    useEffect(()=>{
        const image1 = document.querySelector(".formulaire .image:nth-child(1)")
        const image2 = document.querySelector(".formulaire .image:nth-child(2)")
        if(section_1_Y>0){
            gsap.to(image1,{
                x:0,
                autoAlpha:1,
                duration:1,
            })
            gsap.to(image2,{
                x:"-50%",
                autoAlpha:1,
                duration:1,
                delay:1
            })
        }
    },[section_1_Y]);

    return(
        <>
            <Header subtitle={intl.formatMessage({id:"title-agence-champex-lac", defaultMessage: "Agence champex-lac" })} img={headerImg}>
                {intl.formatMessage({id: "title-contact", defaultMessage: "Contact"})}
            </Header>
            <Section id="informations" className="informations">
                <TwoColumn>
                    <div className="left-box">
                        <div className="map-box">
                            <TitleBox title={intl.formatMessage({id: "title-informations-contact", defaultMessage: "Informations{break} de contact"},{break:<br/>})}></TitleBox>
                            <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2770.037614722019!2d7.114180579975219!3d46.030398095896885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478ecbc5a40ba26f%3A0x759d32b75d6b6ff!2sH%C3%B4tel%20-%20restaurant%20-%20spa%20Au%20Club%20Alpin%20I!5e0!3m2!1sfr!2smx!4v1694900861301!5m2!1sfr!2smx" style={{border:"0"}} width="100%" height="100%" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="right-box">
                        <Textbox>
                            <address>
                                <h6>{intl.formatMessage({id:"contact-info-adresse", defaultMessage: "Adresse"})}</h6><br/>
                                <a href="https://maps.app.goo.gl/P6BBkz3gM4kPMNPv7" target="_blank" rel="external">
                                    Au Club Immobilier<br/>
                                    Route du Lac 42<br/>
                                    1938 Champex-Lac<br/>
                                    Valais, Suisse<br/>
                                </a>
                                <br/>
                                <br/>
                                <h6>{intl.formatMessage({id:"contact-info-contact", defaultMessage: "Contact"})}</h6><br/>
                                <p>
                                    <a href="tel:0275650565">+41 27 565 05 65</a>
                                    <br/>
                                    <a href="mailto:contact@auclubimmobilier.ch">contact@auclubimmobilier.ch</a>
                                    <br/>
                                </p>
                            </address>
                        </Textbox>
                    </div>
                </TwoColumn>
            </Section>
            <Section id={"formulaire"} className="formulaire">
            <TwoColumn revert={true}>
                    <div ref={section_1_image} className="left-box">
                        <ImageBox image1={image1} image2={image2}/>
                    </div>
                    <div className="right-box">
                        <Textbox title={intl.formatMessage({id: "title-formulaire-contact", defaultMessage: "Formulaire de contact"})}>
                            <ContactForm/>
                        </Textbox>
                    </div>
                </TwoColumn>

            </Section>
        </>
    );
};

export default Contact;