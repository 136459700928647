import React, 
{
    useEffect,
    useState 
} from 'react';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { hydrateRoot } from 'react-dom/client';
import {
    LocaleProvider, 
    defineLocale, 
    useLocaleContext
} from "@ct-react/locale";
import buildApolloClient,
{APOLLO_CACHE_SCRIPT_ID} from "./shared/gql";
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';
import { handleMomentJsLocale } from './shared/locale';
import App from "./app/App";

const loadingLocale = defineLocale(navigator.languages || [], window.location.pathname);

const Wrapper = ()=>{
  const { locale } = useLocaleContext();
  const [ initial, setInitial ] = useState<boolean>(true);
  const [ apolloClient, setApolloClient ] = useState(buildApolloClient(locale, false, window.__APOLLO_STATE__));
  
  // clean state transmission from server rendering to client
  delete window.__APOLLO_STATE__;
  const stateScriptTag = document.getElementById(APOLLO_CACHE_SCRIPT_ID);
  !!stateScriptTag && stateScriptTag.parentNode?.removeChild(stateScriptTag);

  useEffect(() => {
      if (initial) {
        setInitial(false);
        return;
      }
      apolloClient.clearStore().then(); //clear queries cached
      setApolloClient(buildApolloClient(locale, false));
    }, [ locale ]);
  
  return(
    <ApolloProvider client={apolloClient}>
        <HelmetProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </HelmetProvider>
    </ApolloProvider>
  )
}


loadableReady()
.then(() => handleMomentJsLocale(loadingLocale))
.then(() => hydrateRoot(
  document.getElementById("app")!,
  <LocaleProvider locale={loadingLocale} beforeLocaleChange={handleMomentJsLocale}>
    <Wrapper />
  </LocaleProvider>    
  ));