import React, {FunctionComponent, RefObject, useEffect, useRef, useState} from "react";
import gsap from "gsap";
import { useIntl } from "react-intl";
import { Header, Section } from "../../components/wrapper/section";
import { ImageBox, OneColumn, Textbox, TwoColumn } from "../../components/wrapper/column";
import { ButtonEmail, ButtonTelNumber } from "../../components/common";

import headerImg from "../../assets/club-immobilier-champex.jpg";
import image1 from "../../assets/au-club-alpin.png";
import image2 from "../../assets/andre-reuse.png";
import organigramme from "../../assets/organigramme.png";
import "./agency.scss";

const Agency:FunctionComponent = ()=>{
    const intl = useIntl();
    const [section_1_Y, setSection_1_Y] = useState<number>(0);

    const section_1_image = useRef<HTMLDivElement|null>(null);

    //Init intersectObserver
    useEffect(() => {
        if(!!section_1_image){
            intersect_section_1(section_1_image)
        }
    }, []);

    //intersect observer section 1
    const intersect_section_1 = (elementRef: RefObject<Element>)=>{
        if(elementRef!=null){
            const node = elementRef?.current! // DOM Ref
            const observer = new IntersectionObserver(
                ([entry]:any) => {
                    setSection_1_Y(entry.intersectionRect.x)
                },
                { rootMargin: window.innerWidth>900?"-50%":"-40%" }
                );
                elementRef&&observer.observe(node)
                return () => observer.disconnect()
        }
    };
      
    //Animate section 1 images
    useEffect(()=>{
    const image1 = document.querySelector(".agency .image:nth-child(1)")
    const image2 = document.querySelector(".agency .image:nth-child(2)")
    if(section_1_Y>0){
        gsap.to(image1,{
            x:0,
            autoAlpha:1,
            duration:1,
        })
        gsap.to(image2,{
            x:"-50%",
            autoAlpha:1,
            duration:1,
            delay:1
        })
    }
    },[section_1_Y]);

    return(
        <>
            <Header subtitle={intl.formatMessage({id:"title-agence-champex-lac", defaultMessage: "Agence champex-lac" })} img={headerImg}>
            {intl.formatMessage({id: "title-agence", defaultMessage: "Notre {break} Agence"},{break:<br/>})}
            </Header>
            <Section id="agency" className="agency" color="var(--primary)">
                <TwoColumn>
                    <div ref={section_1_image} className="left-box">
                        <ImageBox title={intl.formatMessage({id:"title-personne-de-contact", defaultMessage: "Personne{break} de contact" },{break: <br/>})} image1={image1} image2={image2}/>
                    </div>
                    <div className="right-box">
                        <Textbox>
                            <p>
                                {intl.formatMessage({id:"agence-paragraphe-1", defaultMessage: "Fort de plus de 30 ans d’expérience dans le secteur économique et financier, André Reuse possède une solide expertise en négociation et d’excellentes relations dans les domaines bancaire et juridique. Il maîtrise également la construction et les méthodes d’évaluation de biens immobiliers, lui permettant d’apporter une perspective complète et avisée à chaque projet." })}
                            </p>
                            <ButtonTelNumber />
                            <ButtonEmail />
                        </Textbox>
                    </div>
                </TwoColumn>
            </Section>
            <Section id="organigramme" className="organigramme" background="var(--primary)" color="var(--white)">
                <OneColumn title={intl.formatMessage({id:"title-organigramme", defaultMessage: "Organigramme" })}>
                    <div>
                        <img src={organigramme} alt="Organigramme de Au Club Immobilier"/>
                    </div>
                </OneColumn>
            </Section>
        </>
    );
}

export default Agency;