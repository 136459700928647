import React from "react";
import {
  RouteObject, 
  useRoutes, 
} from "react-router";
import { supportedLocales } from "@ct-react/locale";
import Home from "./pages/home/home";
import Agency from "./pages/agency/agency";
import Services from "./pages/services/services";
import Contact from "./pages/contact/contact";
import Champex from "./pages/champex/champex";
import Articles from "./pages/articles/articles";
import ArticleInfo from "./pages/articleInfo/articleInfo";

const routes: RouteObject[] = [
    {
      path : "",
      element : <Home />,
      index : true,
    },
    {
      path : "agence",
      element : <Agency />
    },
    {
      path : "services",
      element : <Services />
    },
    {
      path : "contact",
      element : <Contact />
    },
    {
      path : "champex-lac",
      element : <Champex />
    },
    {
      path : "nos-biens",
      element : <Articles />
    },
    {
      path : "bien/:id",
      element : <ArticleInfo />
    },
    // {
    //   path : "*",
    //   element : <NotFound />
    // }
];

const LocaleRoutes = () => {

  const fullRoutes = supportedLocales.map(l => ({
    path: l.basename,
    children: routes
  } as unknown as RouteObject));

  return useRoutes(fullRoutes);

};

export default LocaleRoutes;
