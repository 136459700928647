
export class SearchAccomodationArticles {
    where:SaleAccommodationSearchRequest;
    first:number|null;
    last:number|null;
    before?:string|null;
    after:string|null;
    constructor(
        first:number|null = 8,
        last:number|null = null,
        before:string|null = null,
        after:string|null = null
    ){
        this.where  = new SaleAccommodationSearchRequest()
        this.first = first;
        this.last = last;
        this.before = before;
        this.after = after
    }
};

export class SaleAccommodationSearchRequest {
    price:null | number[]
    merchants:string|null;
    novelty:boolean|null;
    promoted:boolean|null;
    objectTypes:string[]|null;
    regions:string|null;
    minRooms:number|null;
    minSurface:number|null;
    dishwasher:boolean|null;
    washingMachine:boolean|null;
    fireplace:boolean|null;
    pool:boolean|null;
    sauna:boolean|null;
    lift:boolean|null;
    parking:boolean|null;
    balcony:boolean|null;
    terrace:boolean|null;
    new:boolean|null;
    furnished:boolean|null;
    forForeigners:boolean|null;
    constructor(
        price:null | number[] = null,
        merchants:string|null = null,
        novelty:boolean|null = null,
        promoted:boolean|null = null,
        objectTypes:string[]|null = null,
        regions:string|null = null,
        minRooms:number|null = null,
        minSurface:number|null = null,
        dishwasher:boolean|null = null,
        washingMachine:boolean|null = null,
        fireplace:boolean|null = null,
        pool:boolean|null = null,
        sauna:boolean|null = null,
        lift:boolean|null = null,
        parking:boolean|null = null,
        balcony:boolean|null = null,
        terrace:boolean|null = null,
        newItem:boolean|null = null,
        furnished:boolean|null = null,
        forForeigners:boolean|null = null,
    ){
        this.price = price
        this.merchants = merchants
        this.novelty = novelty
        this.promoted = promoted
        this.objectTypes = objectTypes
        this.regions = regions
        this.minRooms = minRooms
        this.minSurface = minSurface
        this.dishwasher = dishwasher
        this.washingMachine = washingMachine
        this.fireplace = fireplace
        this.pool = pool
        this.sauna = sauna
        this.lift = lift
        this.parking = parking
        this.balcony = balcony
        this.terrace = terrace
        this.new = newItem
        this.furnished = furnished
        this.forForeigners = forForeigners
    }

};
