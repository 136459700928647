import React,{FunctionComponent, useEffect} from "react";
import { useNavigate } from "@ct-react/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { classNames } from "@ct-react/core";

import "./form-callback.scss";

type FormCallbackProps = { 
    succeeded: boolean,
    setState:()=>void
 };

export const FormCallback:FunctionComponent<FormCallbackProps> = ({succeeded,setState}) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const handleOnClick = ()=>{
        navigate(succeeded ? "/" : "/contact");
    };

    const closeWindow = ()=>{
        document.body.style.overflow = "initial";
        setState();
    };

    useEffect(()=>{
        document.body.style.overflow = "hidden";
    },[])

    return(
        <div className="form-result">
            <div className={classNames({succeeded : succeeded})}>
                <FontAwesomeIcon icon={succeeded? faCheck : faXmark}/>
            </div>
            <h3>
                {succeeded?
                            intl.formatMessage({id:"form-callback-email-received",defaultMessage : "Nous avons bien reçu votre e-mail !"})
                            :
                            intl.formatMessage({id:"form-callback-email-send-failed",defaultMessage : "Echec de l'envoie"})
                }
            </h3>
            <p>
                {succeeded?
                            intl.formatMessage({id:"form-callback-will-reply-asap", defaultMessage : "Nous vous répondrons dés que possible"})
                            :
                            intl.formatMessage({id:"form-callback-issue-occured", defaultMessage : "Un problème est survenue"})
                }
            </p>
            <button onClick={handleOnClick}>
                <span>
                {
                    succeeded? intl.formatMessage({id:"btn-back-home",defaultMessage : "Retour à l'accueil"}) : intl.formatMessage({id:"btn-retry",defaultMessage : "Réssayer"}) 
                }
                </span>
            </button>
            <FontAwesomeIcon onClick={closeWindow} icon={faXmark} className="close-form-callback"/>
        </div>
    );
};


